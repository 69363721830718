export const formatCountdown = (countdown: number): string => {
  const hours = Math.floor(countdown / 3_600);
  const minutes = Math.floor((countdown % 3_600) / 60);
  const seconds = countdown % 60;

  let str = "";
  if (hours > 0) {
    str += `${hours} hour${hours > 1 ? "s" : ""}, `;
  }
  if (minutes > 0 || str.length) {
    str += `${minutes} minute${minutes > 1 ? "s" : ""} and `;
  }
  str += `${seconds} second${seconds > 1 ? "s" : ""}`;

  return str;
};
