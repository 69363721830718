import { useCallback } from "react";
import { TOP_BAR_HEIGHT } from "domains/navigation/components/TopBar";
import { useNavBarContext } from "domains/navigation/contexts/NavBarProvider";
import { IconButton } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import {
  Box,
  Fade,
  HStack,
  Image,
  Link,
  Text,
  Tooltip,
} from "@chakra-ui/react";

interface SectionLogoProps {
  isHover?: boolean;
}

export default function SectionLogo({ isHover }: SectionLogoProps) {
  const { isMobile, isCompact, toggle, closeDrawer } = useNavBarContext();

  const handleChevronClick = useCallback(() => {
    if (isMobile) closeDrawer();
    else toggle();
  }, [toggle, closeDrawer, isMobile]);

  return (
    <Box flex={0} w="full">
      <HStack
        align="center"
        justify={isCompact ? "center" : "start"}
        h={`${TOP_BAR_HEIGHT}px`}
        px={isCompact ? 0 : 4}
        borderBottomWidth="1px"
        borderBottomColor="border.500"
        spacing={5}
      >
        <HStack
          as={Link}
          align="center"
          flex={!isCompact ? 1 : undefined}
          m={-2}
          p={2}
          _hover={{
            borderRadius: "md",
            bgColor: "whiteAlpha.200",
          }}
          href="/"
          spacing={3}
          variant="unstyled"
        >
          <Image
            display={isHover && isCompact ? "none" : undefined}
            w="24px"
            minW="24px"
            alt="logo"
            src="/logos/scenario.svg"
          />

          <Fade in={!isCompact} unmountOnExit>
            <Text w="100%" whiteSpace="nowrap" size="body.bold.lg">
              Scenario
            </Text>
          </Fade>
        </HStack>

        <Tooltip
          hasArrow
          isDisabled={isMobile}
          label={isCompact ? "Expand Menu" : "Reduce Menu"}
          placement="right"
        >
          <IconButton
            {...(isCompact
              ? {
                  pos: "absolute",
                }
              : {})}
            display={!isHover && isCompact ? "none" : undefined}
            m={-2}
            p={2}
            h="auto"
            variant="unstyled"
            aria-label="Extend"
            icon={
              <Icon
                id={
                  isCompact ? "Ui/ChevronDoubleRight" : "Ui/ChevronDoubleLeft"
                }
              />
            }
            _hover={{
              borderRadius: "md",
              bgColor: "whiteAlpha.200",
            }}
            onClick={handleChevronClick}
          />
        </Tooltip>
      </HStack>
    </Box>
  );
}
