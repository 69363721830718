import Link from "next/link";
import isBreakpointMobile from "domains/commons/isMobile";
import { formatCreativeUnits } from "domains/commons/misc";
import TopBarSearch from "domains/navigation/components/TopBar/TopBarSearch";
import { useNavBarContext } from "domains/navigation/contexts/NavBarProvider";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import useTeamHasData from "domains/teams/hooks/useTeamHasData";
import { extraTheme } from "domains/theme";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import {
  Center,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useBreakpoint,
} from "@chakra-ui/react";

import BackgroundTasksDropdown from "./BackgroundTasksDropdown";
import HelpButtonDropdown from "./HelpButtonDropdown";
import LinkButtonDropdown from "./LinkButtonDropdown";
import WorkspaceButtonDropdown from "./WorkspaceButtonDropdown";

export const TOP_BAR_HEIGHT = 56;
const DAYS_FOR_ENDING_SOON_WARNING = 5;

export default function TopBar() {
  const { selectedTeam } = useTeamContext();
  const { subscription, totalRemainingCreativeUnits } = usePlanContext();
  const { toggleDrawer, absoluteWidth: navBarWidth } = useNavBarContext();
  const breakpoint = useBreakpoint({ ssr: true });
  const isMobile = isBreakpointMobile(breakpoint);
  const { hasData, isLoading: isDataLoading } = useTeamHasData();

  const showDataDeletionWarning =
    !isDataLoading && hasData && selectedTeam.isFreePlan;
  const planEndingDays =
    subscription?.plan !== "free" &&
    subscription?.renewal?.plan === "free" &&
    moment
      .utc(subscription.renewal.date)
      .subtract(DAYS_FOR_ENDING_SOON_WARNING, "days")
      .isBefore(moment.utc())
      ? moment.utc(subscription.renewal.date).diff(moment.utc(), "days")
      : undefined;
  const canUpgrade =
    !isDataLoading &&
    selectedTeam.isFreePlan &&
    selectedTeam.userRole === "admin";

  return (
    <Flex
      pos="fixed"
      zIndex="banner"
      align="center"
      justify="space-between"
      gap={4}
      w={isMobile ? "100%" : `calc(100% - ${navBarWidth}px)`}
      h={`${TOP_BAR_HEIGHT}px`}
      px={4}
      borderBottomWidth="1px"
      borderBottomColor="border.500"
      transition={extraTheme.transitions.fast}
      bgColor="black.500"
    >
      {/* Left side */}
      <Flex w={"100%"}>
        {isMobile && (
          <IconButton
            aria-label="Open menu"
            onClick={() => toggleDrawer()}
            size={"md"}
            variant="unstyled"
          >
            <Icon id="Layout/MobileMenu" />
          </IconButton>
        )}
        {!isMobile && <TopBarSearch />}
      </Flex>

      {/* Right side */}
      <HStack align="center" spacing={2}>
        {typeof planEndingDays === "number" && (
          <Link
            href={{
              pathname: "/team",
              query: {
                tab: "plans",
              },
            }}
          >
            <Button
              tooltip={`Your Scenario subscription expires ${formatDays(
                planEndingDays
              )}. Your data will then be staged for deletion.`}
              variant="primary"
              colorScheme="warning"
              size="xs"
              leftIcon={<Icon id="Ui/Star" h="12px" />}
              onClick={() =>
                Track(
                  AnalyticsEvents.Navigation.ClickedSubscriptionEndingSoon,
                  { remainingDays: planEndingDays }
                )
              }
            >
              <FormattedMessage
                id="layout.topbar.endingSoon"
                defaultMessage="Ending Soon"
              />
            </Button>
          </Link>
        )}

        {showDataDeletionWarning && (
          <Link
            href={{
              pathname: "/team",
              query: {
                tab: "plans",
              },
            }}
          >
            <Button
              tooltip={`Your subscription ended. Subscribe to a plan to prevent data deletion.`}
              variant="primary"
              colorScheme="warning"
              size="xs"
              leftIcon={<Icon id="Ui/Star" h="12px" />}
              onClick={() =>
                Track(AnalyticsEvents.DataDeletion.ClickedTopBarDataDeletion)
              }
            >
              Pending Data Deletion
            </Button>
          </Link>
        )}

        {!showDataDeletionWarning && canUpgrade && (
          <Link
            href={{
              pathname: "/team",
              query: {
                tab: "plans",
              },
            }}
          >
            <Button
              variant="primary"
              data-testid="topbar-plans-button"
              size="xs"
              onClick={() => Track(AnalyticsEvents.Navigation.ClickedUpgrade)}
            >
              <FormattedMessage
                id="layout.topbar.upgrade"
                defaultMessage="Upgrade"
              />
            </Button>
          </Link>
        )}

        <HStack spacing={0}>
          <BackgroundTasksDropdown />
          <HelpButtonDropdown />
          <LinkButtonDropdown />
        </HStack>

        <HStack spacing={3}>
          <Tooltip
            hasArrow
            isDisabled={totalRemainingCreativeUnits === undefined}
            label={`${Math.round(
              totalRemainingCreativeUnits ?? 0
            ).toLocaleString("en-US")} Creative Unit${
              Math.round(totalRemainingCreativeUnits ?? 0) > 1 ? "s" : ""
            }`}
          >
            <Link
              href={{
                pathname: "/team",
                query: {
                  tab: "plans",
                },
              }}
            >
              <HStack
                h="36px"
                p={1.5}
                pr={2.5}
                borderWidth={1}
                borderRadius="full"
                __css={{
                  "&:hover": {
                    bgColor: "backgroundQuaternary.500",
                  },
                }}
                bgColor="backgroundTertiary.500"
                data-testid="topbar-creative-units"
                spacing={1.5}
              >
                <Center p={1} borderRadius="full" bgColor="whiteAlpha.100">
                  <Icon id="Ui/CuOutlined" h="16px" />
                </Center>
                <Text pt="1px" size="body.md">
                  {totalRemainingCreativeUnits !== undefined
                    ? formatCreativeUnits(totalRemainingCreativeUnits)
                    : "-"}
                </Text>
              </HStack>
            </Link>
          </Tooltip>

          <WorkspaceButtonDropdown />
        </HStack>
      </HStack>
    </Flex>
  );
}

// ------------------------------------

function formatDays(days: number) {
  if (days === 0) return "today";
  else if (days === 1) return "tomorrow";
  else return `in ${days} days`;
}
