import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = definePartsStyle({
  field: {
    _placeholder: {
      color: "gray.500",
    },
    borderWidth: 1,
    borderRadius: "md",
    borderColor: "border.500",
    backgroundColor: "inherit",
    _hover: {
      borderColor: "borderSecondary.500",
    },
    _focus: {
      borderColor: "primary.500 !important",
      boxShadow: "none !important",
    },
  },
});

export const Input = defineMultiStyleConfig({
  variants: { outline },
  defaultProps: { variant: "outline" },
});
