import { useCallback } from "react";
import { convertImageFromHeic } from "domains/assets/utils/convertImage";
import ImageUploading, {
  ImageListType,
  ImageUploadingPropsType,
} from "react-images-uploading";

interface CompatibleImageUploaderProps extends ImageUploadingPropsType {}

export type { ImageListType };

export default function CompatibleImageUploader({
  acceptType,
  dataURLKey = "dataURL",
  onChange,
  ...props
}: CompatibleImageUploaderProps) {
  const handleChange = useCallback<ImageUploadingPropsType["onChange"]>(
    async (value) => {
      const compatibleValue: ImageListType = [];
      for (const image of value) {
        const rawDataURL = image[dataURLKey];
        const dataURL = rawDataURL
          ? await convertImageFromHeic(rawDataURL)
          : undefined;
        compatibleValue.push({ ...image, [dataURLKey]: dataURL });
      }
      onChange(compatibleValue);
    },
    [onChange, dataURLKey]
  );

  return (
    <ImageUploading
      {...props}
      onChange={handleChange}
      dataURLKey={dataURLKey}
      acceptType={acceptType ?? ["jpeg", "jpg", "png", "webp", "heic"]}
    />
  );
}
