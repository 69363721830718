export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Move an item in an array to a new position
 * before: [1, 2, 3, 4, 5]
 * move(0, 2) -> after: [2, 3, 1, 4, 5]
 * */
export function move<T>(array: T[], moveIndex: number, toIndex: number) {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, toIndex),
      item,
      ...array.slice(toIndex, length),
    ];
  }
  return array;
}

// Convert a number representing cents (100 => 1,00) into a readable string with currency ([150, USD] => $1,50)
export function formatPrice(amount: number, currency: "USD" = "USD") {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
    .format(amount / 100)
    .replace(/\.00$/, "");
}

export function formatCreativeUnits(total: number): string {
  if (total < 10_000) {
    return total.toLocaleString("en-US");
  }
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumSignificantDigits: 3,
  }).format(total);
}

// Convert a number into a readable string (1040,45 => 1.040,45)
export function formatNumber(amount: number) {
  return new Intl.NumberFormat("en-US").format(amount);
}

export function parseNumber(value: string) {
  return parseInt(value.replace(/,| /g, ""));
}

export function parseCurrency(value: string): "USD" | undefined {
  const parsedCurrency = value.toUpperCase();
  if (parsedCurrency === "USD") return parsedCurrency;
  else return undefined;
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function formatFileSize(bytes: number, si = false, dp = 1): string {
  const thresh = si ? 1_000 : 1_024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export function extractFirstQueryParam<ReturnType = string>(
  queryParam: string[] | string | undefined,
  options?: {
    validValues: string[];
  }
): ReturnType | undefined {
  const value = queryParam instanceof Array ? queryParam[0] : queryParam;

  if (value && options?.validValues && !options?.validValues.includes(value)) {
    return undefined;
  }

  if (value) {
    return value as ReturnType;
  }

  return undefined;
}

// ------------------------------------

const MIN_SIZE_FOR_MULTIPART = 15_000_000;
const MAX_FILE_SIZE_PER_PART = 10_000_000;
const MIN_PART_COUNT = 2;
const MAX_PART_COUNT = 1_000;

export function getMultipartChunkCount(size: number) {
  if (size < MIN_SIZE_FOR_MULTIPART) {
    return 1;
  }

  const estimatedPartCount = Math.ceil(size / MAX_FILE_SIZE_PER_PART);
  return Math.min(Math.max(estimatedPartCount, MIN_PART_COUNT), MAX_PART_COUNT);
}
