import React, { ReactNode } from "react";
import { GuideKeys } from "domains/guide/constants/guides";
import { extraTheme } from "domains/theme";
import Icon from "domains/ui/components/Icon";
import TourGuideIcon from "domains/ui/components/TourGuideIcon";
import { useHover } from "domains/ui/hooks/useHover";

import {
  Box,
  BoxProps,
  Flex,
  FormControl,
  FormLabel,
  FormLabelProps,
  HStack,
  Select,
  Text,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react";

export interface WithLabelAndTooltipProps {
  label?: ReactNode;
  rightLabel?: ReactNode;
  tooltip?: React.ReactNode;
  premiumTooltip?: React.ReactNode;
  guide?: GuideKeys;
  direction?: "row" | "column";
  children: React.ReactNode;
  labelProps?: FormLabelProps;
  tooltipProps?: Omit<TooltipProps, "children">;
  contentProps?: Omit<BoxProps, "children">;
}

export default function WithLabelAndTooltip({
  label,
  rightLabel,
  tooltip,
  premiumTooltip,
  guide,
  direction = "row",
  children,
  labelProps,
  tooltipProps,
  contentProps,
}: WithLabelAndTooltipProps) {
  const [hoverRef, isHovered] = useHover();

  // check if one is Select component
  const hasChildrenSelect = React.Children.toArray(children).some(
    (child) => React.isValidElement(child) && child.type === Select
  );

  return (
    <Flex
      ref={hoverRef}
      as={FormControl}
      align={direction === "row" ? "center" : "flex-start"}
      direction={direction}
      gap={1}
      h="min-content"
      minH={direction === "row" ? "28px" : ""}
      color="textSecondary"
      _hover={{
        color: "textPrimary",
      }}
      data-group
    >
      {label && (
        <FormLabel
          justifyContent="space-between"
          display="flex"
          w={
            hasChildrenSelect ? "auto" : direction === "column" ? "full" : "45%"
          }
          my={0}
          color="inherit"
          textAlign="center"
          {...labelProps}
        >
          <HStack color="inherit">
            {typeof label === "string" ? (
              <Text color="inherit" isTruncated size="body.md">
                {label}
              </Text>
            ) : (
              label
            )}

            {premiumTooltip && (
              <Tooltip
                hasArrow
                label={premiumTooltip}
                placement="bottom"
                {...tooltipProps}
              >
                <Box m={-1} p={1}>
                  <Icon id="Ui/Stars" color="primary.500" />
                </Box>
              </Tooltip>
            )}

            {tooltip && (
              <Tooltip
                hasArrow
                label={tooltip}
                placement="bottom"
                {...tooltipProps}
              >
                <Box m={-1} p={1}>
                  <Icon
                    id="Ui/Help"
                    opacity={isHovered ? 1 : 0}
                    transition={extraTheme.transitions.fast}
                    color="textTertiary"
                  />
                </Box>
              </Tooltip>
            )}

            {guide && <TourGuideIcon guide={guide} isHovered={isHovered} />}
          </HStack>

          {typeof rightLabel === "string" ? (
            <Text color="inherit" size="body.md">
              {rightLabel}
            </Text>
          ) : (
            rightLabel
          )}
        </FormLabel>
      )}

      <Box flex={1} w="full" color="inherit" {...contentProps}>
        {children}
      </Box>
    </Flex>
  );
}
