import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { formatFileSize, getMultipartChunkCount } from "domains/commons/misc";
import ButtonDroppableUpload from "domains/image/components/ButtonDroppableUpload";
import useImageUploadDragDrop from "domains/image/hooks/useImageUploadDragDrop";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import Select from "domains/ui/components/Select";
import WithLabelAndTooltip from "domains/ui/components/WithLabelAndTooltip";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  PostUploadsApiResponse,
  useGetUploadsByTeamIdQuery,
  usePostUploadsActionByTeamIdMutation,
  usePostUploadsMutation,
} from "infra/api/generated/api";
import { apiSlice } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import store from "infra/store/store";

import {
  Box,
  Code,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export interface ModalUploadModelProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ModalUploadModel({
  isOpen,
  onClose,
}: ModalUploadModelProps) {
  const { selectedTeam } = useTeamContext();
  const { errorToast, successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const [step, setStep] = useState<
    | "input"
    | "confirmation"
    | "upload"
    | "success"
    | "upload_failure"
    | "import_failure"
  >("input");
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();
  const [progress, setProgress] = useState<number>(0);
  const { isDraggingHover, dragFunctions, onDrop } = useImageUploadDragDrop({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadFileTrigger] = usePostUploadsMutation();
  const [runActionOnUploadTrigger] = usePostUploadsActionByTeamIdMutation();
  const [isImportingFromExternalSource, setIsImportingFromExternalSource] =
    useState<boolean>(false);
  const [provider, setProvider] = useState<"huggingface" | "civitai">(
    "huggingface"
  );
  const [providerLink, setProviderLink] = useState<string>("");
  const [uploadData, setUploadData] = useState<
    PostUploadsApiResponse["upload"] | undefined
  >();

  const { data: updatedUploadInfo } = useGetUploadsByTeamIdQuery(
    uploadData
      ? {
          teamId: selectedTeam.id,
          uploadId: uploadData.id,
        }
      : skipToken,
    {
      pollingInterval: !["imported", "failed"].includes(
        uploadData?.status ?? ""
      )
        ? 10_000
        : undefined,
    }
  );

  const successSubStep =
    (updatedUploadInfo?.upload?.status === "failed" && "failed") ||
    (updatedUploadInfo?.upload?.status === "imported" && "imported") ||
    "processing";

  const partsCount = useMemo(
    () => getMultipartChunkCount(fileToUpload?.size ?? 1),
    [fileToUpload]
  );

  // ----------------------------------

  const close = useCallback(() => {
    onClose();
    setStep("input");
    setFileToUpload(undefined);
    setProgress(0);
    setProvider("huggingface");
    setProviderLink("");
    setUploadData(undefined);
  }, [onClose]);

  // ----------------------------------

  const handleFileDrop = useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      event.preventDefault();
      const fileToUpload = event.dataTransfer.files[0];
      setFileToUpload(fileToUpload);
      setStep("confirmation");
    },
    [setFileToUpload]
  );

  const handleFileInputChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const fileToUpload = (event.currentTarget.files ?? [])[0];
      setFileToUpload(fileToUpload);
      setStep("confirmation");
    },
    [setFileToUpload]
  );

  const handleUploadClick = useCallback(async () => {
    if (!fileToUpload) return;

    setStep("upload");
    try {
      const fileMimeType = guessMimeType(fileToUpload.name);
      if (!fileMimeType) throw new Error("INVALID_FILE_TYPE");

      const uploadInfo = await uploadFileTrigger({
        teamId: selectedTeam.id,
        body: {
          fileName: fileToUpload.name,
          fileSize: fileToUpload.size,
          kind: "model",
          parts: partsCount,
          contentType: fileMimeType,
        },
      }).unwrap();

      if (!uploadInfo.upload.parts) {
        throw new Error("Invalid upload response");
      }
      setUploadData(uploadInfo.upload);

      const chunkSize = Math.ceil(fileToUpload.size / partsCount);

      for (let i = 0; i < partsCount; i++) {
        setProgress(i);
        const chunk = fileToUpload.slice(i * chunkSize, chunkSize * (i + 1));
        const chunkUrl = (uploadInfo.upload.parts ?? [])[i].url;

        await fetch(chunkUrl, { method: "PUT", body: chunk });
      }

      await runActionOnUploadTrigger({
        teamId: selectedTeam.id,
        uploadId: uploadInfo.upload.id,
        body: {
          action: "complete",
        },
      }).unwrap();

      Track(AnalyticsEvents.Model.Uploaded, {
        size: fileToUpload.size,
        type: fileMimeType,
      });
      successToast({ title: "Model has been uploaded." });
      setStep("success");
    } catch (err: any) {
      if (err.message === "INVALID_FILE_TYPE") {
        errorToast({
          title: "Invalid File Type",
          description:
            "Only .zip, .gzip, .gz and .safetensors files are accepted.",
        });
        close();
      } else {
        handleApiError(err, "Error uploading file");
        setStep("upload_failure");
      }
    }
  }, [
    uploadFileTrigger,
    runActionOnUploadTrigger,
    setProgress,
    setStep,
    errorToast,
    successToast,
    handleApiError,
    close,
    fileToUpload,
    partsCount,
    selectedTeam.id,
  ]);

  const importFromExternalSource = useCallback(async () => {
    if (!providerLink) return;

    let body = {};
    if (provider === "huggingface") {
      body = {
        huggingFaceModelName: providerLink.trim(),
      };
    } else if (provider === "civitai") {
      body = {
        civitaiModelUrl: providerLink.trim(),
      };
    } else {
      return;
    }

    setIsImportingFromExternalSource(true);

    try {
      const uploadInfo = await uploadFileTrigger({
        teamId: selectedTeam.id,
        body: {
          kind: "model",
          ...body,
        },
      }).unwrap();
      setUploadData(uploadInfo.upload);

      successToast({ title: "Model has been imported" });
      setStep("success");
    } catch (error) {
      handleApiError(error, "Error importing model");
      setStep("import_failure");
    }

    setIsImportingFromExternalSource(false);
  }, [
    uploadFileTrigger,
    successToast,
    handleApiError,
    provider,
    providerLink,
    selectedTeam.id,
  ]);

  // ----------------------------------

  useEffect(() => {
    if (
      ["imported", "failed"].includes(updatedUploadInfo?.upload?.status ?? "")
    ) {
      setUploadData(updatedUploadInfo?.upload);
    }
  }, [updatedUploadInfo?.upload]);

  useEffect(() => {
    if (uploadData?.status === "imported") {
      setTimeout(async () => {
        store.dispatch(
          apiSlice.util.invalidateTags([{ type: API_TAGS.model }])
        );
      }, 100);
    }
  }, [uploadData?.status]);

  // ----------------------------------

  return (
    <Modal
      closeOnOverlayClick={[
        "input",
        "success",
        "upload_failure",
        "import_failure",
      ].includes(step)}
      isOpen={isOpen}
      onClose={step !== "upload" ? close : () => {}}
      variant="action"
    >
      <ModalOverlay />
      <ModalContent overflow="hidden" p={0}>
        {step !== "upload" && <ModalCloseButton />}
        <ModalHeader>
          <Text flex={1} textAlign="left" size="body.bold.lg">
            {"Upload a Model"}
          </Text>
        </ModalHeader>

        <ModalBody overflow="hidden" w="100%" p={6}>
          {step === "input" && (
            <VStack pos="relative" w="100%" spacing={4}>
              {isOpen && (
                <Box
                  ref={fileInputRef}
                  as="input"
                  pos="absolute"
                  w="1px"
                  h="1px"
                  visibility="hidden"
                  onChange={handleFileInputChange}
                  type="file"
                />
              )}

              <ButtonDroppableUpload
                type="file"
                dropText="Drop Safetensors file"
                message={
                  <>
                    <span>{"Scenario supports "}</span>
                    <Code>.safetensors</Code>
                    <span>{", "}</span>
                    <Code>.zip</Code>
                    <span>{", "}</span>
                    <Code>.gzip</Code>
                    <span>{" and "}</span>
                    <Code>.gz</Code>
                    <span>{" files."}</span>
                  </>
                }
                isLoading={false}
                isDraggingHover={isDraggingHover}
                onDrop={(event) => onDrop(event, handleFileDrop)}
                onClick={() => fileInputRef.current?.click()}
                {...dragFunctions}
              />

              <Divider label="OR" w="100%" />

              <VStack align="stretch" w="100%" spacing={2}>
                <HStack>
                  <Select
                    w="fit-content"
                    value={provider}
                    flexShrink={0}
                    textAlign="start"
                    onChange={(e) =>
                      setProvider(e.target.value as typeof provider)
                    }
                  >
                    <option value="huggingface">Hugging Face</option>
                    <option value="civitai">CivitAI</option>
                  </Select>
                  <ScenarioInput
                    containerProps={{ flex: 1 }}
                    placeholder={
                      provider === "civitai"
                        ? "Paste model link"
                        : "Paste model name, e.g. stabilityai/stable-diffusion-xl-base-1.0"
                    }
                    value={providerLink}
                    setValue={setProviderLink}
                    onEnter={importFromExternalSource}
                  />
                </HStack>
                <Button
                  size="sm"
                  variant="primary"
                  isDisabled={!providerLink}
                  onClick={importFromExternalSource}
                  isLoading={isImportingFromExternalSource}
                >
                  Import
                </Button>
                <Text
                  textColor="textSecondary"
                  textAlign="right"
                  fontStyle="italic"
                  size="body.sm"
                >
                  Larger files take longer to upload and process.
                </Text>
              </VStack>
            </VStack>
          )}

          {step === "confirmation" && (
            <WithLabelAndTooltip label="File">
              <Text size="body.md">{`${fileToUpload?.name} (${formatFileSize(
                fileToUpload?.size ?? 0,
                true
              )})`}</Text>
            </WithLabelAndTooltip>
          )}

          {step === "upload" && (
            <VStack p={10} pt={6} spacing={2}>
              <Text color="textSecondary" size="body.md">
                {`Uploading... (${Math.round((progress / partsCount) * 100)}%)`}
              </Text>

              <Progress max={partsCount} min={0} value={progress} />
            </VStack>
          )}

          {step === "success" && successSubStep === "failed" && (
            <ResultMessage
              icon={<Icon id="Ui/AlertError" color="danger.500" />}
              title={`An Error Occurred While Processing the Model`}
              message={`Please try again later or contact support.`}
            />
          )}

          {step === "success" && successSubStep === "imported" && (
            <VStack align="stretch">
              <ResultMessage
                icon={<Icon id="Ui/AlertSuccess" color="success.500" />}
                title="Success!"
                message={`Your model has been successfully processed and is ready for you to use.`}
                button={
                  updatedUploadInfo?.upload?.entityId ? (
                    <Button
                      size="sm"
                      variant="primary"
                      internalLink={{
                        pathname: "/models/[id]",
                        query: {
                          id: updatedUploadInfo?.upload.entityId,
                        },
                      }}
                      onClick={close}
                    >
                      Access Model
                    </Button>
                  ) : undefined
                }
              />
            </VStack>
          )}

          {step === "success" && successSubStep === "processing" && (
            <VStack align="center" p={10} pt={6} spacing={2}>
              <Text color="textPrimary" size="body.bold.lg">
                Your model is being processed
              </Text>
              <Progress
                w={"350px"}
                h={1.5}
                isIndeterminate
                rounded="base"
                size="xs"
              />
              <Text align="center" color="textSecondary" size="body.md">
                {`Your model has been successfully ${
                  uploadData?.source === "multipart" ? "uploaded" : "imported"
                }. It's currently being processed and should be ready shortly. You can close this window, you'll receive an email notification once it's ready for use.`}
              </Text>
            </VStack>
          )}

          {step === "upload_failure" && (
            <ResultMessage
              icon={<Icon id="Ui/AlertError" color="danger.500" />}
              title={`Model Upload Failed`}
              message={`Please verify that the uploaded file is a valid model.`}
            />
          )}

          {step === "import_failure" && (
            <ResultMessage
              icon={<Icon id="Ui/AlertError" color="danger.500" />}
              title={`Model Import Failed`}
              message={`Please verify that the ${
                provider === "civitai" ? "link" : "model name"
              } is correct.`}
            />
          )}
        </ModalBody>

        {step === "confirmation" && (
          <ModalFooter>
            <HStack>
              <Button onClick={close} size="sm" variant="secondary">
                Cancel
              </Button>

              <Button size="sm" variant="primary" onClick={handleUploadClick}>
                Upload
              </Button>
            </HStack>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

// ------------------------------------

interface ResultMessageProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  message: React.ReactNode;
  button?: React.ReactNode;
}
function ResultMessage({ icon, title, message, button }: ResultMessageProps) {
  return (
    <VStack align="center" p={10} pt={6} spacing={4}>
      <VStack spacing={2}>
        <HStack spacing={3}>
          {icon}
          <Text color="textPrimary" size="body.bold.lg">
            {title}
          </Text>
        </HStack>
        <Text align="center" color="textSecondary" size="body.md">
          {message}
        </Text>
      </VStack>

      {button}
    </VStack>
  );
}

// ------------------------------------

function guessMimeType(fileName: string) {
  if (fileName.endsWith(".zip")) return "application/zip";
  else if (fileName.endsWith(".gzip") || fileName.endsWith(".gz"))
    return "application/gzip";
  else if (fileName.endsWith(".safetensors")) return "application/safetensors";
  else return undefined;
}
