import { PostSearchApiTransformedResponse } from "domains/search/interfaces/Search";
import { PostSearchApiArg } from "infra/api/generated/api";
import { apiSlice } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";
import _ from "lodash";

export const searchEndpoints: Endpoints = {
  postSearch: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      const { data } = await queryFulfilled;
      if (data) {
        dispatch(
          apiSlice.util.updateQueryData("postSearch", arg, (draft) => {
            const { incomingLength, nextPage } = getIncomingLengthAndNextPage(
              data,
              arg
            );
            draft.incomingLength = incomingLength;
            draft.nextPage = nextPage;
          })
        );
      }
    },
    merge(existing, incoming, { arg }) {
      existing.results = _.unionBy(existing.results, incoming.results, "id");

      // This fix is in case some assets don't update
      // existing.results = _.unionBy(
      //   _.cloneDeep(existing.results),
      //   incoming.results,
      //   "id"
      // );

      const { incomingLength, nextPage } = getIncomingLengthAndNextPage(
        incoming,
        arg
      );
      existing.incomingLength = incomingLength;
      existing.nextPage = nextPage;
    },
    serializeQueryArgs: ({ endpointName, queryArgs }) => {
      return endpointName + queryArgs.teamId + JSON.stringify(queryArgs.body);
    },
    forceRefetch({ currentArg, previousArg }) {
      return !_.isEqual(currentArg, previousArg);
    },
    providesTags: [API_TAGS.search],
  },
};

const getIncomingLengthAndNextPage = (
  data: PostSearchApiTransformedResponse,
  arg: PostSearchApiArg
) => {
  const incomingLength = data.results.length;
  const currentPage = parseInt(arg.pageNumber ?? "0");
  const nextPage =
    incomingLength.toString() === arg.pageSize ? currentPage + 1 : currentPage;
  return { incomingLength, nextPage };
};
