import { useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import Loading from "domains/ui/components/Loading";
import _ from "lodash";

import { Box, Center } from "@chakra-ui/react";
import { useAuth, useClerk, useSignIn } from "@clerk/nextjs";

const publicRoutes = ["/unsubscribe", "/down"];
const loggedOutOnlyRoutes = ["/login", "/see-you-soon"];

const deferred = {} as any;

export const getAccessToken = async (): Promise<string | undefined> => {
  if (deferred.getToken) {
    return await deferred.getToken();
  }
  return undefined;
};

export function AuthRedirectGuard({ children }: { children: React.ReactNode }) {
  const { userId, getToken, isLoaded, actor } = useAuth();
  const { handleRedirectCallback } = useClerk();
  const { signIn } = useSignIn();
  const router = useRouter();
  const isPublicRoute = useMemo(() => {
    return publicRoutes.includes(router.pathname);
  }, [router.pathname]);
  const isLoggedOutOnlyRoute = useMemo(() => {
    return loggedOutOnlyRoutes.includes(router.pathname);
  }, [router.pathname]);

  useEffect(() => {
    deferred.getToken = getToken;
  }, [getToken]);

  useEffect(() => {
    if (!isLoaded || !router.isReady) return;

    if (isPublicRoute) {
      return;
    }

    if (isLoggedOutOnlyRoute && !!userId) {
      void router.replace("/");
    }

    if (!isLoggedOutOnlyRoute && !userId) {
      const referral = router.query.referral;
      void router.replace({
        pathname: "/login",
        query: referral ? { referral } : {},
      });
    }
  }, [isLoaded, userId, router, isLoggedOutOnlyRoute, isPublicRoute]);

  /** Do not flash the public route if the user is authenticated before redirection */
  const willRedirect =
    (!isPublicRoute && isLoggedOutOnlyRoute === !!userId) ||
    router.query.handleLoginRedirect;

  useEffect(() => {
    if (signIn && router.isReady && router.query.handleLoginRedirect) {
      if (
        signIn?.firstFactorVerification?.error?.code ===
        "external_account_not_found"
      ) {
        void handleRedirectCallback(
          {
            transferable: true,
          },
          async (path) => {
            window.location.href = path;
          }
        );
      } else {
        void router.push({
          pathname: router.pathname,
          query: _.omit(router.query, "handleLoginRedirect"),
        });
      }
    }
  }, [signIn, handleRedirectCallback, router]);

  return !isLoaded || willRedirect ? (
    <Center h="100vh">
      <Loading />
    </Center>
  ) : (
    <>
      {!!actor && (
        <Box
          pos="absolute"
          zIndex="99999999999"
          w="100vw"
          h="100vh"
          borderWidth="6px"
          borderColor="danger.500"
          pointerEvents="none"
          bgColor="transparent"
        />
      )}
      {children}
    </>
  );
}
