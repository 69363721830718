import { tagIdToLabel } from "domains/tags/utils";

export function createTagUpdateMessage(
  tagsToAdd: string[],
  tagsToRemove: string[]
) {
  if (tagsToAdd.length && tagsToRemove.length) {
    return `Tags "${tagsToAdd
      .map(tagIdToLabel)
      .join('", "')}" have been added and tags "${tagsToRemove
      .map(tagIdToLabel)
      .join('", "')}" have been removed.`;
  } else if (tagsToAdd.length) {
    return `Tag${tagsToAdd.length > 1 ? "s" : ""} "${tagsToAdd
      .map(tagIdToLabel)
      .join('", "')}" ${tagsToAdd.length > 1 ? "have" : "has"} been added.`;
  } else if (tagsToRemove.length) {
    return `Tag${tagsToRemove.length > 1 ? "s" : ""} "${tagsToRemove
      .map(tagIdToLabel)
      .join('", "')}" ${
      tagsToRemove.length > 1 ? "have" : "has"
    } been removed.`;
  } else {
    return "No tags were added or removed."; // Handling case where no tags are changed (e.g. when adding a tag that is already present)
  }
}
