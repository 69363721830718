import { apiSlice } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";

export const subscriptionsEndpoints: Endpoints = {
  getTeamsSubscriptionsByTeamId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      const { data } = await queryFulfilled;
      if (data) {
        const plan = data?.subscription?.plan;
        if (plan) {
          dispatch(
            apiSlice.util.updateQueryData("getTeams", undefined, (draft) => {
              if (!draft) {
                return;
              }

              const team = draft.teams.find((team) => team.id === arg.teamId);
              if (team) {
                team.plan = plan;
              }
            })
          );
        }
      }
    },
    providesTags: [API_TAGS.subscription],
  },
  getTeamsLimitsByTeamId: {
    providesTags: [API_TAGS.subscription, API_TAGS.limits],
  },
};
