import { MAX_BASE64_UPLOAD_SIZE } from "domains/assets/constants/upload";
import { getBase64Size } from "domains/assets/utils";
import { default as picaStatic } from "pica";

export async function compressImageForUpload(
  image: string
): Promise<{ data: string; wasCompressed: boolean } | undefined> {
  return await new Promise((resolve) => {
    const img = document.createElement("img");
    img.onload = () => {
      let imageSize: number | undefined;
      try {
        imageSize = getBase64Size(image);
      } catch (_) {
        resolve(undefined);
        return;
      }
      if (MAX_BASE64_UPLOAD_SIZE > imageSize) {
        resolve({
          data: image,
          wasCompressed: false,
        });
        return;
      }

      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const quality = Math.min(0.85, MAX_BASE64_UPLOAD_SIZE / imageSize);

      const pica = picaStatic();

      void pica
        .resize(img, canvas, {
          filter: "lanczos3",
        })
        .then((result) => pica.toBlob(result, "image/jpeg", quality))
        .then((result) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (!reader.result || typeof reader.result !== "string") {
              resolve(undefined);
              return;
            }
            resolve({
              data: reader.result,
              wasCompressed: true,
            });
          };
          reader.readAsDataURL(result);
        });
    };
    img.onerror = () => {
      resolve(undefined);
    };
    img.src = image;
  });
}
