import { Select as ChakraSelect, SelectProps } from "@chakra-ui/react";

const Select = ({ children, ...props }: SelectProps) => {
  return (
    <ChakraSelect
      sx={{
        "::placeholder": {
          color: "#808080",
        },
      }}
      borderColor={"white.500"}
      borderRadius={"2px"}
      {...props}
    >
      {children}
    </ChakraSelect>
  );
};

export default Select;
