import { GetCollectionsByCollectionIdApiResponse } from "infra/api/generated/api";
import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export const updateCollectionsQueryByUpdatingCollection = (
  collection: GetCollectionsByCollectionIdApiResponse["collection"],
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.collection,
  ]) as SelectedInvalidatedByTag[]) {
    if (endpointName === "getCollections") {
      dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (draft) {
            const collectionIndex = draft.collections.findIndex(
              (item) => item.id === collection.id
            );
            if (collectionIndex !== -1) {
              draft.collections[collectionIndex] = collection;
              draft.collections.sort((a, b) => {
                return a.name.localeCompare(b.name);
              });
            }
          }
        })
      );
    }
  }
};
