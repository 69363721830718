import { createTagUpdateMessage } from "domains/tags/utils/createTagUpdateMessage";
import { tagIdToLabel } from "domains/tags/utils/tagIdToLabel";
import { isTagValid } from "domains/tags/utils/validateTag";

export function expandTagItems(tag: string): string[] {
  if (tag.startsWith("sc:") && !tag.startsWith("sc:category")) {
    const parts = tag.split(":");
    if (parts.length === 3) {
      const category = parts[1];
      const subcategory = parts[2];
      return [`sc:${category}`, `sc:${category}:${subcategory}`];
    }
  }
  return [tag];
}

export function formatTagsArray(tags: string[]): Record<string, string[]> {
  return tags.reduce((acc, tag) => {
    const [prefix, mainTag, secondTag] = tag.split(":");
    const mainTagWithPrefix = prefix + ":" + mainTag;
    const secondTagWithPrefix = prefix + ":" + mainTag + ":" + secondTag;
    if (prefix !== "sc") {
      return acc;
    }
    if (!acc[mainTagWithPrefix]) {
      acc[mainTagWithPrefix] = [];
    }
    if (secondTag) {
      acc[mainTagWithPrefix].push(secondTagWithPrefix);
    }

    return acc;
  }, {} as Record<string, string[]>);
}

export { createTagUpdateMessage, isTagValid, tagIdToLabel };
