export const MAX_TAG_LENGTH = 128;
export const ALLOWED_TAG_CHARACTERS = /^[A-Za-z0-9_.:/=+\-@]*$/;

export const responsiveMaxTagBreakpoints = {
  values: {
    1_500: 30,
    1_280: 15,
    992: 9,
    768: 3,
    480: 3,
    360: 3,
  },
  default: 9,
};
