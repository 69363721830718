export const BLEND_MAX_CONCEPTS = 5;
export const BLEND_SCALE_VALUES = [1, 0.75, 0.65, 0.55, 0.45];

export type BlendCategory = {
  label: string;
  tagId: string;
  thumbnail: string;
  categories?: Omit<BlendCategory, "categories">[];
};

export const BLEND_CATEGORIES: BlendCategory[] = [
  {
    label: "Style",
    tagId: "sc:style",
    thumbnail: "/blend-categories/style.jpg",
    categories: [
      {
        label: "Illustration",
        tagId: "sc:style:illustration",
        thumbnail: "/blend-categories/style-illustration.jpg",
      },
      {
        label: "Line Art",
        tagId: "sc:style:line-art",
        thumbnail: "/blend-categories/style-line-art.jpg",
      },
      {
        label: "3D",
        tagId: "sc:style:3D",
        thumbnail: "/blend-categories/style-3d.jpg",
      },
      {
        label: "Cartoon",
        tagId: "sc:style:cartoon",
        thumbnail: "/blend-categories/style-cartoon.jpg",
      },
      {
        label: "Painting",
        tagId: "sc:style:painting",
        thumbnail: "/blend-categories/style-painting.jpg",
      },
      {
        label: "Anime",
        tagId: "sc:style:anime",
        thumbnail: "/blend-categories/style-anime.jpg",
      },
      {
        label: "Digital Art",
        tagId: "sc:style:digital-art",
        thumbnail: "/blend-categories/style-digital-art.jpg",
      },
      {
        label: "Realism",
        tagId: "sc:style:realism",
        thumbnail: "/blend-categories/style-realism.jpg",
      },
      {
        label: "Photography",
        tagId: "sc:style:photography",
        thumbnail: "/blend-categories/style-photography.jpg",
      },
      {
        label: "Minimalism",
        tagId: "sc:style:minimalism",
        thumbnail: "/blend-categories/style-minimalism.jpg",
      },
      {
        label: "Retro",
        tagId: "sc:style:retro",
        thumbnail: "/blend-categories/style-retro.jpg",
      },
    ],
  },
  {
    label: "Subject",
    tagId: "sc:subject",
    thumbnail: "/blend-categories/subject.jpg",
  },
  {
    label: "Theme",
    tagId: "sc:theme",
    thumbnail: "/blend-categories/theme.jpg",
    categories: [
      {
        label: "Fantasy",
        tagId: "sc:theme:fantasy",
        thumbnail: "/blend-categories/theme-fantasy.jpg",
      },
      {
        label: "RPG",
        tagId: "sc:theme:rpg",
        thumbnail: "/blend-categories/theme-rpg.jpg",
      },
      {
        label: "Surreal",
        tagId: "sc:theme:surreal",
        thumbnail: "/blend-categories/theme-surreal.jpg",
      },
      {
        label: "Sci-Fi",
        tagId: "sc:theme:scifi",
        thumbnail: "/blend-categories/theme-scifi.jpg",
      },
      {
        label: "Ethereal",
        tagId: "sc:theme:ethereal",
        thumbnail: "/blend-categories/theme-ethereal.jpg",
      },
      {
        label: "Holiday",
        tagId: "sc:theme:holiday",
        thumbnail: "/blend-categories/theme-holiday.jpg",
      },
      {
        label: "Nature",
        tagId: "sc:theme:nature",
        thumbnail: "/blend-categories/theme-nature.jpg",
      },
      {
        label: "Supernatural",
        tagId: "sc:theme:supernatural",
        thumbnail: "/blend-categories/theme-supernatural.jpg",
      },
    ],
  },
  {
    label: "Asset Category",
    tagId: "sc:asset-type",
    thumbnail: "/blend-categories/asset-type.jpg",
    categories: [
      {
        label: "Backgrounds",
        tagId: "sc:asset-type:backgrounds",
        thumbnail: "/blend-categories/asset-type-backgrounds.jpg",
      },
      {
        label: "Props",
        tagId: "sc:asset-type:props",
        thumbnail: "/blend-categories/asset-type-props.jpg",
      },
      {
        label: "UI",
        tagId: "sc:asset-type:ui",
        thumbnail: "/blend-categories/asset-type-ui.jpg",
      },
      {
        label: "Isometric Art",
        tagId: "sc:asset-type:isometric",
        thumbnail: "/blend-categories/asset-type-isometric.jpg",
      },
      {
        label: "Platformer Assets",
        tagId: "sc:asset-type:platformer",
        thumbnail: "/blend-categories/asset-type-platformer.jpg",
      },
      {
        label: "Icons & Buttons",
        tagId: "sc:asset-type:icons",
        thumbnail: "/blend-categories/asset-type-icons.jpg",
      },
      {
        label: "Concept Art",
        tagId: "sc:asset-type:concept-art",
        thumbnail: "/blend-categories/asset-type-concept-art.jpg",
      },
      {
        label: "Character Art",
        tagId: "sc:asset-type:character-art",
        thumbnail: "/blend-categories/asset-type-character-art.jpg",
      },
    ],
  },
];
