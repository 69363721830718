import Icon from "domains/ui/components/Icon";

import { selectAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  Select as ChakraSelect,
} from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

export const Select = defineMultiStyleConfig({
  variants: {
    default: definePartsStyle({
      icon: {
        backgroundColor: "whiteAlpha.50",
        borderRadius: "md",
        width: 5,
        h: "min-content",
        py: 0.5,
      },
      field: {
        _focusVisible: {
          boxShadow: "none !important",
        },
        textAlign: "right",
        borderWidth: "0px", // remove border from the field
        borderColor: "transparent", // make border color transparent
        borderRadius: "md",
        backgroundColor: "transparent",
        cursor: "pointer",
        _hover: {
          backgroundColor: "whiteAlpha.50",
          "& + .chakra-select__icon-wrapper": {
            backgroundColor: "transparent",
          },
        },
        option: {
          backgroundColor: "backgroundSecondary.500",
        },
      },
    }),
  },

  defaultProps: {
    variant: "default",
    size: "sm",
  },

  sizes: {
    sm: definePartsStyle({
      icon: {
        right: 1,
      },
      field: {
        paddingInlineStart: 2,
        paddingInlineEnd: 7,
      },
    }),
  },
});

// Chakra don't allow to override props that are not style related, so here is how to do it for the icon everywhere
// https://github.com/chakra-ui/chakra-ui/issues/1424#issuecomment-743342944
ChakraSelect.defaultProps = {
  ...ChakraSelect.defaultProps,
  icon: <Icon id="Domains/Theme/ComponentSelectArrows" />,
  iconSize: "16px",
};
