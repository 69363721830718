import sizeof from "object-sizeof";

import { AnyAction, isRejectedWithValue, Middleware } from "@reduxjs/toolkit";

export const apiResponseMiddleware: Middleware =
  () => (next) => (action: AnyAction) => {
    try {
      if (
        action.payload?.status === 503 &&
        window.location.pathname !== "/down"
      ) {
        window.location.replace("/down");
      } else if (
        isRejectedWithValue()(action) &&
        sizeof((action.meta.arg as any).originalArgs.body) > 6 * 1_024 * 1_024 // 6mb
      ) {
        action.payload = {
          status: 413,
        };
      }
    } catch (_) {}

    return next(action);
  };
