import { formatCreativeUnits } from "domains/commons/misc";
import { extraTheme } from "domains/theme";
import { sizes } from "domains/theme/components/text";
import Button, {
  ButtonGoogleTranslateFix,
  ButtonProps,
} from "domains/ui/components/Button";

import {
  HStack,
  Spinner,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";

interface CuIndicatorProps extends StackProps {
  cuCost?: number;
  isCuLoading: boolean;
  isTooltipDisabled?: boolean;
}

export function CuIndicator({
  cuCost,
  isCuLoading,
  isTooltipDisabled,
  ...props
}: CuIndicatorProps) {
  if (cuCost === undefined && !isCuLoading) {
    return null;
  }

  return (
    <Tooltip
      isDisabled={isTooltipDisabled}
      label={
        isCuLoading
          ? "Estimating Creative Unit cost"
          : `Uses ${cuCost} creative ${(cuCost ?? 0) > 1 ? "units" : "unit"}`
      }
    >
      <Stack align="stretch" p={2} {...props}>
        <HStack
          pos="relative"
          flex={1}
          h={"18px"}
          px={2}
          py={1}
          fontSize={sizes["body.sm"].fontSize}
          borderWidth={1}
          borderColor={isCuLoading ? "whiteAlpha.200" : "whiteAlpha.400"}
          borderRadius="md"
          transition={extraTheme.transitions.fast}
          bgColor="whiteAlpha.200"
          spacing={1}
        >
          {isCuLoading && (
            <Spinner
              pos="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              m="auto"
              size="xs"
              thickness="1.5px"
            />
          )}
          <Text
            opacity={isCuLoading ? 0 : 1}
            data-testid="cu-indicator-value"
            size="body.sm"
          >{`${formatCreativeUnits(cuCost ?? 0)}`}</Text>
        </HStack>
      </Stack>
    </Tooltip>
  );
}

// ------------------------------------

interface ButtonWithCuIndicatorProps extends ButtonProps {
  cuCost?: number;
  isCuLoading: boolean;
}

export default function ButtonWithCuIndicator({
  cuCost,
  isCuLoading,
  children,
  ...props
}: ButtonWithCuIndicatorProps) {
  return (
    <Button pos="relative" {...props}>
      <ButtonGoogleTranslateFix>{children}</ButtonGoogleTranslateFix>
      <CuIndicator
        pos="absolute"
        top={0}
        right={0}
        bottom={0}
        cuCost={cuCost}
        isCuLoading={isCuLoading}
      />
    </Button>
  );
}
