import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import isBreakpointMobile from "domains/commons/isMobile";
import {
  DEFAULT_NAV_BAR_SETTINGS,
  NAV_BAR_COMPACT_WIDTH,
  NAV_BAR_WIDE_WIDTH,
  NavBarSettings,
} from "domains/navigation/constants/Navigation";

import { useBreakpoint, useDisclosure } from "@chakra-ui/react";

interface NavBarContextValue {
  isMobile: boolean;
  isCompact: boolean;
  reduce: () => void;
  expand: () => void;
  toggle: () => void;
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  toggleDrawer: () => void;
  collapsedItems: string[];
  setCollapsedItems: (items: string[]) => void;
  width: number;
  absoluteWidth: number;
}

export const NavBarContext = createContext<NavBarContextValue>({
  isMobile: false,
  isCompact: false,
  expand: () => {},
  reduce: () => {},
  toggle: () => {},
  isDrawerOpen: false,
  openDrawer: () => {},
  closeDrawer: () => {},
  toggleDrawer: () => {},
  collapsedItems: [],
  setCollapsedItems: () => {},
  width: 0,
  absoluteWidth: 0,
});

export function useNavBarContext() {
  return useContext<NavBarContextValue>(NavBarContext);
}

// ------------------------------------

export function NavBarProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const breakpoint = useBreakpoint();
  const isMobile = isBreakpointMobile(breakpoint);
  const {
    isOpen: isDrawerOpen,
    onOpen: openDrawer,
    onClose: closeDrawer,
  } = useDisclosure();
  const [navBarSettings, setNavBarSettings] = usePersistedState<NavBarSettings>(
    PersistedStateKey.NAV_BAR_SETTINGS,
    { defaultValue: DEFAULT_NAV_BAR_SETTINGS }
  );

  const isCompact = isMobile ? false : navBarSettings.isCompact;
  const collapsedItems = useMemo(
    () => (isMobile ? [] : navBarSettings.collapsedItems),
    [isMobile, navBarSettings.collapsedItems]
  );
  const width = isMobile
    ? NAV_BAR_WIDE_WIDTH
    : isCompact
    ? NAV_BAR_COMPACT_WIDTH
    : NAV_BAR_WIDE_WIDTH;
  const absoluteWidth = isMobile
    ? 0
    : isCompact
    ? NAV_BAR_COMPACT_WIDTH
    : NAV_BAR_WIDE_WIDTH;

  // ----------------------------------

  const expand = useCallback(() => {
    setNavBarSettings((settings) => ({ ...settings, isCompact: false }));
  }, [setNavBarSettings]);
  const reduce = useCallback(() => {
    setNavBarSettings((settings) => ({ ...settings, isCompact: true }));
  }, [setNavBarSettings]);
  const toggle = useCallback(() => {
    setNavBarSettings((settings) => ({
      ...settings,
      isCompact: !settings.isCompact,
    }));
  }, [setNavBarSettings]);

  const toggleDrawer = useCallback(() => {
    if (isDrawerOpen) closeDrawer();
    else openDrawer();
  }, [isDrawerOpen, openDrawer, closeDrawer]);

  const setCollapsedItems = useCallback(
    (items: string[]) => {
      setNavBarSettings((settings) => ({ ...settings, collapsedItems: items }));
    },
    [setNavBarSettings]
  );

  // ----------------------------------

  useEffect(() => {
    if (!isMobile) closeDrawer();
  }, [isMobile, closeDrawer]);

  // ----------------------------------

  const contextValue = useMemo(
    () => ({
      isMobile,
      isCompact,
      expand,
      reduce,
      toggle,
      isDrawerOpen,
      openDrawer,
      closeDrawer,
      toggleDrawer,
      collapsedItems,
      setCollapsedItems,
      width,
      absoluteWidth,
    }),
    [
      isMobile,
      isCompact,
      expand,
      reduce,
      toggle,
      isDrawerOpen,
      openDrawer,
      closeDrawer,
      toggleDrawer,
      collapsedItems,
      setCollapsedItems,
      width,
      absoluteWidth,
    ]
  );

  return (
    <NavBarContext.Provider value={contextValue}>
      {children}
    </NavBarContext.Provider>
  );
}
