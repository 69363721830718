// eslint-disable-next-line unused-imports/no-unused-vars
let logger: typeof console.log = (message: string, ...args: any[]) => {};

if (process.env.NODE_ENV === "development") {
  logger = (message: string, ...args: any[]) => {
    console.log(message, ...args);
  };
}

export default logger;
