import { BLEND_CATEGORIES } from "domains/models/constants/blend";

// Define the type for a transformation rule
type TransformationRule = {
  regex: RegExp;
  replacement?: string;
  replacer?: (substring: string, ...args: any[]) => string;
};

// Define a set of transformation rules
const transformationRules: TransformationRule[] = [
  // Rule to replace underscores (_) and hyphens (-) with spaces
  { regex: /[_-]/g, replacement: " " },

  // Rule to remove the prefix containing the category. ex: 'sc:category:', 'sc:style:
  { regex: /sc:(.*):/g, replacement: "" },

  // Rule to remove the prefix 'sc:'
  { regex: /sc:/g, replacement: "" },

  // Rule to change a lowercase letter following a digit to uppercase
  // Example: "3a" becomes "3A"
  {
    regex: /(\d)([a-z])/gi,
    replacer: (match: string, p1: string, p2: string) =>
      `${p1}${p2.toUpperCase()}`,
  },

  // Rule to replace 'ui' with 'UI', case-insensitive
  { regex: /\bui\b/gi, replacement: "UI" },

  // Rule to capitalize the first letter of each word
  { regex: /\b\w/g, replacer: (char: string) => char.toUpperCase() },
];

export function tagIdToLabel(tagId: string): string {
  for (const category of BLEND_CATEGORIES) {
    if (category.tagId === tagId) {
      return category.label;
    }
    for (const subCategory of category.categories ?? []) {
      if (subCategory.tagId === tagId) {
        return subCategory.label;
      }
    }
  }

  return transformationRules.reduce((transformedTagId, rule) => {
    if (rule.replacement !== undefined) {
      return transformedTagId.replace(rule.regex, rule.replacement);
    } else if (rule.replacer !== undefined) {
      return transformedTagId.replace(rule.regex, rule.replacer);
    }
    return transformedTagId;
  }, tagId);
}

export function tagIdToObject(tag: string | undefined) {
  const label = tag && tagIdToLabel(tag);
  if (label) return { id: tag, label };
  else return undefined;
}
