import { PutModelsByModelIdApiResponse } from "infra/api/generated/api";
import { apiSlice } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export const updateQueryDataByUpdatingModelConcepts = ({
  updatedModel,
  dispatch,
  getState,
}: {
  updatedModel: PutModelsByModelIdApiResponse["model"];
  dispatch: ThunkDispatch<any, any, AnyAction>;
  getState: () => AppRootState;
}) => {
  const invalidatedEndpoints = apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.model,
  ]);
  for (const { endpointName, originalArgs } of invalidatedEndpoints) {
    if (endpointName === "getModels") {
      dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          const modelToUpdate = draft?.models?.find(
            (model) => model.id === updatedModel.id
          );
          if (modelToUpdate) {
            modelToUpdate.concepts = updatedModel.concepts;
          }
        })
      );
    }
    if (endpointName === "getModelsByModelId") {
      dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (draft?.model && draft.model.id === updatedModel.id) {
            draft.model.concepts = updatedModel.concepts;
          }
        })
      );
    }
  }
};
