import { PropsWithChildren } from "react";
import Icon from "domains/ui/components/Icon";

import {
  Box,
  Center,
  HStack,
  StackProps,
  Text,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react";

interface PremiumBadgeProps extends Omit<StackProps, "size"> {
  size?: "md" | "lg";
}

export function PremiumBadge({ children, size, ...props }: PremiumBadgeProps) {
  return (
    <HStack
      px={size === "lg" ? 2 : 1.5}
      py={0.5}
      bg="primarySoft"
      borderWidth={1}
      borderColor="#1B3745"
      borderRadius="2xl"
      spacing={1}
      {...props}
    >
      <Icon id="Ui/Star" color="#5CBDED" h={size === "lg" ? "14px" : "10px"} />
      <Text textColor="#5CBDED" size={size === "lg" ? "body.lg" : "body.sm"}>
        {children}
      </Text>
    </HStack>
  );
}

// ------------------------------------

interface PremiumButtonWrapperProps extends PropsWithChildren {
  shouldShowWrapper: boolean;
  shouldShowBorder?: boolean;
  tooltip?: TooltipProps["label"];
  size?: "md" | "lg";
}

export function PremiumButtonWrapper({
  shouldShowWrapper,
  shouldShowBorder = true,
  children,
  tooltip,
  size = "md",
}: PremiumButtonWrapperProps) {
  if (!shouldShowWrapper) {
    return <>{children}</>;
  }

  return (
    <Tooltip label={tooltip}>
      <Box pos="relative">
        {children}

        <Box
          pos="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          borderWidth={shouldShowBorder ? (size === "lg" ? 4 : 2) : 0}
          borderColor="border.500"
          borderRadius={size === "lg" ? "xl" : "md"}
          pointerEvents="none"
        />

        <Center
          pos="absolute"
          top={0}
          right={0}
          w={size === "lg" ? "30px" : "16px"}
          h={size === "lg" ? "30px" : "16px"}
          borderRadius={size === "lg" ? "md" : "base"}
          borderTopRightRadius={size === "lg" ? "xl" : "md"}
        >
          <Icon
            id="Ui/Star"
            color="primary.500"
            h={size === "lg" ? "16px" : "12px"}
          />
        </Center>
      </Box>
    </Tooltip>
  );
}
