import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";

export const inferencesEndpoints: Endpoints = {
  postUpscaleInferences: {
    invalidatesTags: (_result, _error, _arg) => {
      return [
        {
          type: API_TAGS.asset,
          // TODO uncomment when the API allows get assets by parent id
          // id: `assetId:${arg.body.image}`,
        },
        {
          type: API_TAGS.asset,
          id: `type:upscale`,
        },
        { type: API_TAGS.limits },
        { type: API_TAGS.job },
      ];
    },
  },

  postRestyleInferences: {
    invalidatesTags: [
      { type: API_TAGS.asset },
      { type: API_TAGS.limits },
      { type: API_TAGS.job },
    ],
  },

  postVectorizeInferences: {
    invalidatesTags: (_result, _error, _arg) => {
      return [
        {
          type: API_TAGS.asset,
          // TODO uncomment when the API allows get assets by parent id
          // id: `assetId:${arg.body.assetId}`,
        },
        {
          type: API_TAGS.asset,
          id: `type:vectorization`,
        },
        { type: API_TAGS.limits },
        { type: API_TAGS.job },
      ];
    },
  },

  postPixelateInferences: {
    invalidatesTags: (_result, _error, _arg) => {
      return [
        {
          type: API_TAGS.asset,
          // TODO uncomment when the API allows get assets by parent id
          // id: `assetId:${arg.body.assetId}`,
        },
        {
          type: API_TAGS.asset,
          id: `type:pixelization`,
        },
        { type: API_TAGS.limits },
        { type: API_TAGS.job },
      ];
    },
  },

  postRemoveBackgroundInferences: {
    invalidatesTags: (_result, _error) => {
      return [
        {
          type: API_TAGS.asset,
          // TODO uncomment when the API allows get assets by parent id
          // id: `assetId:${arg.body.assetId}`,
        },
        {
          type: API_TAGS.asset,
          id: `type:background-removal`,
        },
        { type: API_TAGS.limits },
        { type: API_TAGS.job },
      ];
    },
  },
};
