import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export const updateQueryDataByUpdatingDescription = ({
  assetId,
  description,
  dispatch,
  getState,
}: {
  assetId: string;
  description: string;
  dispatch: ThunkDispatch<any, any, AnyAction>;
  getState: () => AppRootState;
}) => {
  const undoList: (() => void)[] = [];

  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.model,
  ]) as SelectedInvalidatedByTag[]) {
    if (endpointName === "getModels") {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (draft) {
            for (const model of draft.models) {
              const assetIndex = model.trainingImages.findIndex(
                (item) => item.id === assetId
              );
              if (assetIndex !== -1) {
                model.trainingImages[assetIndex].description = description;
              }
            }
          }
        })
      );
      undoList.push(update.undo);
    }

    if (endpointName === "getModelsByModelId") {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (draft?.model?.trainingImages) {
            const assetIndex = draft.model.trainingImages.findIndex(
              (item) => item.id === assetId
            );
            if (assetIndex !== -1) {
              draft.model.trainingImages[assetIndex].description = description;
            }
          }
        })
      );
      undoList.push(update.undo);
    }
  }

  return {
    undo: () => {
      undoList.forEach((undo) => undo());
    },
  };
};
