import type { ComponentStyleConfig } from "@chakra-ui/react";

export const sizes = {
  "title.2xl": {
    fontSize: "48px",
    fontWeight: "600",
  },
  "title.xl": {
    fontSize: "32px",
    fontWeight: "600",
  },
  "title.lg": {
    fontSize: "28px",
    fontWeight: "600",
  },
  "title.md": {
    fontSize: "22px",
    fontWeight: "600",
  },
  "title.sm": {
    fontSize: "18px",
    fontWeight: "600",
  },
  "title.xs": {
    fontSize: "12px",
    fontWeight: "500",
    textTransform: "uppercase",
  },
  "body.bold.lg": {
    fontSize: "16px",
    fontWeight: "600",
  },
  "body.bolder.md": {
    fontSize: "14px",
    fontWeight: "600",
  },
  "body.2xl": {
    fontSize: "24px",
    fontWeight: "400",
  },
  "body.xl": {
    fontSize: "21px",
    fontWeight: "400",
  },
  "body.lg": {
    fontSize: "16px",
    fontWeight: "400",
  },
  "body.bold.md": {
    fontSize: "14px",
    fontWeight: "500",
  },
  "body.md": {
    fontSize: "14px",
    fontWeight: "400",
  },
  "body.bold.sm": {
    fontSize: "12px",
    fontWeight: "500",
  },
  "body.sm": {
    fontSize: "12px",
    fontWeight: "400",
  },
  "body.xs": {
    fontSize: "10px",
    fontWeight: "400",
  },
};

export const Text: ComponentStyleConfig = {
  baseStyle: {
    color: "textPrimary",
  },

  sizes: sizes,
};
