import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";

export const teamsFixedKeys = {
  deleteTeamMember: "deleteTeamMember",
  postTeamMember: "postTeamMember",
};

export const teamsEndpoints: Endpoints = {
  getTeams: { providesTags: [API_TAGS.team] },
  postTeams: { invalidatesTags: [API_TAGS.team] },

  putTeamsByTeamId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data?.team) {
        for (const {
          endpointName,
          originalArgs,
        } of apiSlice.util.selectInvalidatedBy(getState(), [
          API_TAGS.team,
        ]) as SelectedInvalidatedByTag[]) {
          if (
            endpointName === "getTeams" ||
            (endpointName === "getTeamsByTeamId" &&
              originalArgs.teamId === data.team.id)
          ) {
            dispatch(
              apiSlice.util.updateQueryData(
                endpointName,
                originalArgs,
                (draft) => {
                  if (!draft) {
                    return;
                  }

                  if (endpointName === "getTeams" && "teams" in draft) {
                    const teamIndex = draft.teams.findIndex(
                      (item) => item.id === data.team.id
                    );
                    if (teamIndex !== -1) {
                      draft.teams[teamIndex] = {
                        ...draft.teams[teamIndex],
                        ...arg.body,
                      };
                    }
                  } else if (
                    endpointName === "getTeamsByTeamId" &&
                    "team" in draft
                  ) {
                    draft.team = { ...draft.team, ...arg.body };
                  }
                }
              )
            );
          }
        }
      }
    },
  },
  getTeamsByTeamId: { providesTags: [API_TAGS.team] },
  deleteTeamsByTeamId: { invalidatesTags: [API_TAGS.team] },

  postTeamsMembersByTeamId: {
    invalidatesTags: [API_TAGS.team, API_TAGS.limits],
  },
  putTeamsMembersByTeamIdAndMemberId: {
    invalidatesTags: [API_TAGS.team, API_TAGS.limits],
  },
  deleteTeamsMembersByTeamIdAndMemberId: {
    invalidatesTags: [API_TAGS.team, API_TAGS.limits],
  },

  postTeamsApiKeysByTeamId: { invalidatesTags: [API_TAGS.team] },
  putTeamsApiKeyByTeamIdAndKeyId: { invalidatesTags: [API_TAGS.team] },
  deleteTeamsApiKeyByTeamIdAndKeyId: { invalidatesTags: [API_TAGS.team] },

  deleteTeamsInvitationsByTeamId: {
    invalidatesTags: [API_TAGS.team, API_TAGS.limits],
  },
};
