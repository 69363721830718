import { useCallback } from "react";
import ScenarioToast, {
  ScenarioToastStatus,
} from "domains/notification/components/ScenarioToast";

import { useToast, UseToastOptions } from "@chakra-ui/react";

const toastErrorOptions: UseToastOptions = {
  position: "top",
  status: "error",
  duration: 5_000,
  isClosable: true,
};

const toastSuccessOptions: UseToastOptions = {
  position: "top",
  status: "success",
  duration: 5_000,
  isClosable: true,
};

const toastInfoOptions: UseToastOptions = {
  status: "info",
  duration: 5_000,
  position: "top",
  isClosable: true,
};

export function useScenarioToast() {
  const toast = useToast();

  const renderToast = useCallback(
    (options: UseToastOptions) => {
      toast({
        ...options,
        containerStyle: {
          maxWidth: "unset",
        },
        render: ({ onClose }) => (
          <ScenarioToast
            title={options.title as string}
            description={`${options.description || ""}`}
            status={options.status as ScenarioToastStatus}
            onClose={onClose}
          />
        ),
      });
    },
    [toast]
  );

  const successToast = useCallback(
    (options: UseToastOptions) => {
      renderToast({
        ...toastSuccessOptions,
        ...options,
      });
    },
    [renderToast]
  );

  const errorToast = useCallback(
    (options: UseToastOptions) => {
      renderToast({
        ...toastErrorOptions,
        ...options,
      });
    },
    [renderToast]
  );

  const infoToast = useCallback(
    (options: UseToastOptions) => {
      renderToast({
        ...toastInfoOptions,
        ...options,
      });
    },
    [renderToast]
  );

  return {
    successToast,
    errorToast,
    infoToast,
  };
}
