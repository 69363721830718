import { useEffect, useRef, useState } from "react";

export default function useCountdown(initialSeconds: number): number {
  const [seconds, setSeconds] = useState(initialSeconds);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const isEnded = seconds <= 0;

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1_000);
    return () => clearInterval(intervalRef.current);
  }, [setSeconds]);

  useEffect(() => {
    if (!isEnded) return;
    clearInterval(intervalRef.current);
  }, [isEnded]);

  return seconds;
}
