import {
  DowngradeSurvey,
  Periodicity,
  Plan,
  PlanId,
  Plans,
} from "domains/teams/interfaces/Pricing";

export const PERIODICITY: Periodicity[] = ["month", "year"];

export const ADDITIONAL_SEAT_PRICE = { month: 5_000, year: 50_000 };

const PLAN_ENTERPRISE: Plan = {
  id: "enterprise",
  label: "Enterprise",
  subLabel: "Custom",
  keyFeatures: [
    "Commercial use of generated content",
    "Your data remains private",
    "Onboarding Assistance",
    "Dedicated Account Team",
    "Workshops and Team Training",
    "Premium Support via dedicated Slack channel",
    "Advanced Security & Compliance",
    "Specific Service Level Agreement",
    "Tailored AI model training",
    "Access to Early Features or Beta Programs",
    "Custom Creative Units Allowance",
    "Custom Seats Allowance",
    "Custom Rate Limits",
    "Custom Storage",
    "Custom Deployments",
    "Dedicated GPUs",
  ],
  link: { label: "Talk To Us!", white: true },
  details: {
    collaborativeWorkspace: true,
    creativeUnits: {
      cuPerMonth: "Custom",
      buyMoreCu: true,
    },
    images: {
      rateLimit: "Custom",
      enhancements: true,
      fullSizeDownload: true,
      upscaleMax: "up to 16x",
      maxImagesPerBatch: "up to 16",
      maxSamplingSteps: "up to 150",
      textures: true,
      skyboxes: true,
      concurrentGeneration: "Custom",
    },
    canvas: {
      numberOfProjects: "Uncapped",
      numberOfLayers: "Uncapped",
      snapshotDuration: "Uncapped",
      realTime: true,
    },
    models: {
      train: true,
      concurrentTraining: true,
      maxTrainingImages: "100",
      rateLimit: "Custom",
      blending: true,
      uploads: true,
      exports: true,
    },
    workspaceManagement: {
      seats: "Custom",
      additionalSeats: "Custom",
      maxCollections: "Uncapped",
      storage: "Custom",
      apiAccess: true,
      sso: true,
      soc2: true,
    },
    support: {
      supportTier: "Advanced",
      knowledgeCenter: true,
      tickets: true,
      trainingAssistance: true,
      accountManager: true,
    },
  },
  support: [
    {
      title: "All Team Features Included",
    },
    {
      plus: true,
      title: "Custom Onboarding Assistance",
      text: "Get up to speed quickly with our tailored onboarding sessions.",
    },
    {
      plus: true,
      title: "Custom Model Training",
      text: "Benefit from full-service model training support, designed to make onboarding seamless. Advanced models offer bespoke solutions with the ability to iterate and produce near-production-ready outputs.",
    },
    {
      plus: true,
      title: "24/7 Dedicated Support",
      text: "Our global team is available 24/7 to provide assistance when you need it most, on your preferred communication channel such as a shared Slack channel.",
    },
    {
      plus: true,
      title: "Dedicated Team Training Sessions",
      text: "Our Customer Success team will deliver the latest training on Scenario's features and generative AI workflows, tailored to your needs.",
    },
  ],
  options: {
    supportFormId: "6745e3d9-dee0-48a3-983d-70a3dcbaabab",
  },
};

export const PLANS: Plans = {
  free: {
    id: "free",
    label: "Free",
    subLabel: undefined,
    amount: "Free",
    keyFeatures: [
      "Your data is retained for 1 month",
      "No generation services",
      "Please reach out to learn about specific educational or artist program coupons, or to request a custom demo",
    ],
    support: [],
    details: {
      collaborativeWorkspace: false,
      creativeUnits: {
        cuPerMonth: "500",
        cuEquivalent: "Make around 100 images or 33 upscales",
        buyMoreCu: false,
      },
      images: {
        rateLimit: "Variable",
        enhancements: true,
        fullSizeDownload: false,
        upscaleMax: "up to 2x",
        maxImagesPerBatch: "up to 2",
        maxSamplingSteps: "up to 30",
        textures: true,
        skyboxes: true,
        concurrentGeneration: "-",
      },
      canvas: {
        numberOfProjects: "-",
        numberOfLayers: "-",
        snapshotDuration: "-",
        realTime: false,
      },
      models: {
        train: true,
        concurrentTraining: false,
        maxTrainingImages: "15",
        rateLimit: "Variable",
        blending: true,
        uploads: false,
        exports: false,
      },
      workspaceManagement: {
        seats: "1",
        additionalSeats: "-",
        maxCollections: "5",
        storage: "3 Go",
        apiAccess: false,
        sso: false,
        soc2: true,
      },
      support: {
        supportTier: "Community",
        knowledgeCenter: true,
        tickets: false,
        trainingAssistance: false,
        accountManager: false,
      },
    },
  },
  "cu-creator": {
    id: "cu-creator",
    label: "Creator",
    amount: { month: 1_500, year: 14_400 },
    keyFeatures: [
      "Commercial use of generated content",
      "Your data remains private",
      "1,500 Creative Units",
      "Standard Processing",
      "3 Concurrent Generations",
      "4x Factor Upscaling",
      "High-Resolution Download",
      "15GB Storage Allowance",
      "API Access",
    ],
    link: {},
    details: {
      collaborativeWorkspace: false,
      creativeUnits: {
        cuPerMonth: "1,500",
        cuEquivalent: "Make around 300 images or 100 upscales",
        buyMoreCu: true,
      },
      images: {
        rateLimit: "2,000",
        enhancements: true,
        fullSizeDownload: true,
        upscaleMax: "up to 4x",
        maxImagesPerBatch: "up to 4",
        maxSamplingSteps: "up to 30",
        textures: true,
        skyboxes: true,
        concurrentGeneration: "3",
      },
      canvas: {
        numberOfProjects: "20",
        numberOfLayers: "Uncapped",
        snapshotDuration: "30 days",
        realTime: true,
      },
      models: {
        train: true,
        concurrentTraining: false,
        maxTrainingImages: "30",
        rateLimit: "50",
        blending: true,
        uploads: true,
        exports: true,
      },
      workspaceManagement: {
        seats: "1",
        additionalSeats: "-",
        maxCollections: "20",
        storage: "15 Go",
        apiAccess: true,
        sso: false,
        soc2: true,
      },
      support: {
        supportTier: "Basic",
        knowledgeCenter: true,
        tickets: true,
        trainingAssistance: false,
        accountManager: false,
      },
    },
    support: [
      {
        title: "Join Our Discord Community",
        text: "Connect with fellow creators and game professionals in our supportive Discord server. Share ideas, ask questions, and learn from the successes of others.",
      },
      {
        title: "Feature Request Board",
        text: "Make your voice heard by requesting new features or upvoting existing requests directly within our app.",
      },
      {
        title: "Comprehensive Knowledge Center & Automated Support",
        text: "Access a public, user-friendly knowledge center with articles tailored for all experience levels. Our knowledge center bot simplifies navigation and offers quick solutions.",
      },
      {
        title: "Ticket Support",
      },
    ],
    options: {
      supportFormId: "a41beb50-c3db-4b3d-b718-27d0fe9ec9f4",
    },
  },
  "cu-pro": {
    id: "cu-pro",
    label: "Pro",
    amount: { month: 4_500, year: 43_200 },
    keyFeatures: [
      "Commercial use of generated content",
      "Your data remains private",
      "5,000 Creative Units",
      "Priority Processing",
      "Uncapped Low Priority Generations*",
      "5 Concurrent Generations",
      "16x Factor Upscaling",
      "High-Resolution Download",
      "50GB Storage Allowance",
      "API Access",
    ],
    link: {},
    details: {
      collaborativeWorkspace: false,
      creativeUnits: {
        cuPerMonth: "5,000",
        cuEquivalent: "Make around 1,000 images or 333 upscales",
        buyMoreCu: true,
      },
      images: {
        rateLimit: "2,000",
        enhancements: true,
        fullSizeDownload: true,
        upscaleMax: "up to 16x",
        maxImagesPerBatch: "up to 8",
        maxSamplingSteps: "up to 150",
        textures: true,
        skyboxes: true,
        concurrentGeneration: "5",
      },
      canvas: {
        numberOfProjects: "100",
        numberOfLayers: "Uncapped",
        snapshotDuration: "90 days",
        realTime: true,
      },
      models: {
        train: true,
        concurrentTraining: false,
        maxTrainingImages: "50",
        rateLimit: "100",
        blending: true,
        uploads: true,
        exports: true,
      },
      workspaceManagement: {
        seats: "1",
        additionalSeats: "-",
        maxCollections: "100",
        storage: "50 Go",
        apiAccess: true,
        sso: false,
        soc2: true,
      },
      support: {
        supportTier: "Enhanced",
        knowledgeCenter: true,
        tickets: true,
        trainingAssistance: false,
        accountManager: false,
      },
    },
    support: [
      {
        title: "All Creator Features Included",
      },
      {
        plus: true,
        title: "Priority Ticket Support",
        text: "Receive faster response times and personalized assistance through our dedicated ticketing system.",
      },
    ],
    options: {
      supportFormId: "620f121c-6584-4040-9bf0-9c19b3de98ec",
    },
  },
  "cu-team": {
    id: "cu-team",
    label: "Team",
    amount: { month: 15_000, year: 144_000 },
    keyFeatures: [
      "Commercial use of generated content",
      "Your data remains private",
      "20,000 Creative Units",
      "High Priority Processing",
      "Uncapped Low Priority Generations*",
      "15 Concurrent Generations",
      "Concurrent Trainings",
      "16x Factor Upscaling",
      "High-Resolution Download",
      "100GB Storage Allowance",
      "Multi-user workspace",
      "Priority Support",
      "API Access",
    ],
    link: {},
    details: {
      collaborativeWorkspace: true,
      creativeUnits: {
        cuPerMonth: "20,000",
        cuEquivalent: "Make around 4,000 images or 1,250 upscales",
        buyMoreCu: true,
      },
      images: {
        rateLimit: "5,000",
        enhancements: true,
        fullSizeDownload: true,
        upscaleMax: "up to 16x",
        maxImagesPerBatch: "up to 16",
        maxSamplingSteps: "up to 150",
        textures: true,
        skyboxes: true,
        concurrentGeneration: "15",
      },
      canvas: {
        numberOfProjects: "Uncapped",
        numberOfLayers: "Uncapped",
        snapshotDuration: "Uncapped",
        realTime: true,
      },
      models: {
        train: true,
        concurrentTraining: true,
        maxTrainingImages: "100",
        rateLimit: "Custom",
        blending: true,
        uploads: true,
        exports: true,
      },
      workspaceManagement: {
        seats: "3",
        additionalSeats: ADDITIONAL_SEAT_PRICE,
        maxCollections: "Uncapped",
        storage: "100 Go",
        apiAccess: true,
        sso: false,
        soc2: true,
      },
      support: {
        supportTier: "Advanced",
        knowledgeCenter: true,
        tickets: true,
        trainingAssistance: false,
        accountManager: false,
      },
    },
    support: [
      {
        title: "All Pro Features Included",
      },
      {
        plus: true,
        title: "High Priority Ticket Support",
        text: "Enjoy faster response times for help tickets with a dedicated sales team on your case.",
      },
      {
        plus: true,
        title: "Exclusive Master Class Webinars",
        text: "Gain access to regular webinars and master classes for you and your team.",
      },
    ],
    options: {
      supportFormId: "6745e3d9-dee0-48a3-983d-70a3dcbaabab",
    },
  },
  enterprise: PLAN_ENTERPRISE,
  custom: { ...PLAN_ENTERPRISE, label: "Custom" },
};

export const SUBSCRIPTION_PLANS: PlanId[] = [
  "free",
  "cu-creator",
  "cu-pro",
  "cu-team",
  "enterprise",
];

export const SUPPORT_PLANS: PlanId[] = [
  "free",
  "cu-creator",
  "cu-pro",
  "cu-team",
  "enterprise",
];

export const INITIAL_DOWNGRADE_SURVEY: DowngradeSurvey = {
  duration: undefined,
  frequency: undefined,
  reason: [],
  reasonDetails: "",
  satisfaction: {
    easeOfUse: undefined,
    featureSet: undefined,
    performance: undefined,
    reliability: undefined,
    valueForMoney: undefined,
    customerSupport: undefined,
  },
  missingFeatures: "",
  serviceImprovements: "",
  returnReasons: "",
};
