import { apiSlice } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";

export const usersEndpoints: Endpoints = {
  putMe: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      const update = dispatch(
        apiSlice.util.updateQueryData("getMe", undefined, (draft) => {
          if (!draft) {
            return;
          }

          draft.user = {
            ...draft.user,
            ...arg.body,
            settings: {
              ...draft.user.settings,
              ...arg.body.settings,
            },
          };
        })
      );

      queryFulfilled.catch(() => update.undo());
    },
    invalidatesTags: [API_TAGS.user],
  },
  getMe: {
    providesTags: [API_TAGS.user],
  },
  putUserNotificationsByToken: {
    invalidatesTags: [API_TAGS.user],
  },
};
