export async function convertImageFromHeic(
  image: string
): Promise<string | undefined> {
  if (typeof window === "undefined") return;
  if (!image.startsWith("data:image/heic") && !image.endsWith(".heic")) {
    return image;
  }

  const { default: heic2any } = await import("heic2any");

  const res = await fetch(image);
  const heicBlob = await res.blob();
  const jpgBlob = await heic2any({
    blob: heicBlob,
    toType: "image/jpeg",
  });

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (!result || typeof result !== "string") {
        resolve(undefined);
        return;
      }
      resolve(result);
    };
    reader.readAsDataURL(Array.isArray(jpgBlob) ? jpgBlob[0] : jpgBlob);
  });
}
