// eslint-disable-next-line prefer-const,no-var
export var ERROR_TO_CANCEL_NAVIGATION =
  "Abort route change. Please ignore this error.";

export type NavBarSettings = {
  isCompact: boolean;
  collapsedItems: string[];
};

export const DEFAULT_NAV_BAR_SETTINGS: NavBarSettings = {
  isCompact: true,
  collapsedItems: [],
};

export const NAV_BAR_WIDE_WIDTH = 248;
export const NAV_BAR_COMPACT_WIDTH = 56;
