import { useEffect, useMemo, useState } from "react";
import {
  FileModelType,
  mapModelsToFiles,
} from "domains/file-manager/interfaces";
import { ModelBase, ModelTag } from "domains/models/constants/tags";
import { tagIdToLabel } from "domains/tags/utils";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetModelsApiArg,
  GetModelsApiResponse,
  GetModelsByModelIdApiResponse,
  GetRecommendationsModelsApiArg,
  useGetModelsQuery,
  useGetRecommendationsModelsQuery,
} from "infra/api/generated/api";
import _ from "lodash";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export type PrivacyFilter = "private" | "public";

export type UseAllModelsArgs = {
  statusesFilter?: GetModelsByModelIdApiResponse["model"]["status"][];
  basesFilter?: ModelBase[];
  typesFilter?: GetModelsByModelIdApiResponse["model"]["type"][];
  tagsFilter?: ModelTag[];
  privacyFilter?: PrivacyFilter;
  searchTerm?: string;
  customFilter?: (file: FileModelType) => boolean;
  collectionId?: string;
  isFeaturedPrioritized?: boolean;
  // sortBy?: GetModelsApiArg["sortBy"];
  // sortDirection?: GetModelsApiArg["sortDirection"];
};

export default function useAllModels({
  statusesFilter,
  basesFilter,
  typesFilter,
  tagsFilter,
  privacyFilter,
  searchTerm,
  customFilter,
  collectionId,
  isFeaturedPrioritized,
}: // sortBy,
// sortDirection,
UseAllModelsArgs) {
  const { selectedTeam } = useTeamContext();
  const [paginationTokenFeatured, setPaginationTokenFeatured] = useState<
    string | undefined
  >();
  const [paginationTokenPublic, setPaginationTokenPublic] = useState<
    string | undefined
  >();
  const [paginationTokenPrivate, setPaginationTokenPrivate] = useState<
    string | undefined
  >();

  const paramsCommon: GetModelsApiArg = useMemo<GetModelsApiArg>(
    () => ({
      pageSize: "100",
      teamId: selectedTeam.id,
      collectionId,
      // sortBy,
      // sortDirection,
    }),
    [selectedTeam.id, collectionId]
  );

  const paramsFeatured = useMemo<
    GetRecommendationsModelsApiArg | typeof skipToken
  >(() => {
    if (privacyFilter === "private" || !isFeaturedPrioritized) {
      return skipToken;
    }
    return {
      ...paramsCommon,
      privacy: "public",
      paginationToken: paginationTokenFeatured,
      tags: "sc:featured",
      limit: "30",
    };
  }, [
    paramsCommon,
    paginationTokenFeatured,
    privacyFilter,
    isFeaturedPrioritized,
  ]);

  const paramsPublic = useMemo<GetModelsApiArg | typeof skipToken>(() => {
    if (privacyFilter === "private") {
      return skipToken;
    }
    return {
      ...paramsCommon,
      privacy: "public",
      paginationToken: paginationTokenPublic,
    };
  }, [paramsCommon, paginationTokenPublic, privacyFilter]);

  const paramsPrivate = useMemo<GetModelsApiArg | typeof skipToken>(() => {
    if (privacyFilter === "public") {
      return skipToken;
    }
    return {
      ...paramsCommon,
      privacy: "private",
      paginationToken: paginationTokenPrivate,
    };
  }, [paramsCommon, paginationTokenPrivate, privacyFilter]);

  const { data: dataFeatured, isLoading: isLoadingFeatured } =
    useGetRecommendationsModelsQuery(paramsFeatured);
  const { data: dataPublic, isLoading: isLoadingPublic } =
    useGetModelsQuery(paramsPublic);
  const { data: dataPrivate, isLoading: isLoadingPrivate } =
    useGetModelsQuery(paramsPrivate);

  const models = useMemo<GetModelsApiResponse["models"]>(
    () =>
      _.uniqBy(
        [
          ...(paramsFeatured === skipToken || !dataFeatured?.models
            ? []
            : dataFeatured.models.filter(
                (model) =>
                  model.tags.includes("sc:featured") &&
                  !model.tags.includes("sc:hidden")
              )),
          ...(paramsPrivate === skipToken || !dataPrivate?.models
            ? []
            : // we need this because after a transfer the model privacy becomes "unlisted" and it causes a few bugs
              dataPrivate.models.map((model) => ({
                ...model,
                privacy:
                  "private" as GetModelsApiResponse["models"][number]["privacy"],
              }))),
          ...(paramsPublic === skipToken || !dataPublic?.models
            ? []
            : dataPublic.models.filter(
                (model) => !model.tags.includes("sc:hidden")
              )),
        ],
        "id"
      ),
    [
      paramsFeatured,
      paramsPublic,
      paramsPrivate,
      dataFeatured?.models,
      dataPublic?.models,
      dataPrivate?.models,
    ]
  );
  const isLoading = !!(
    isLoadingFeatured ||
    isLoadingPublic ||
    isLoadingPrivate
  );
  const hasMore = !!(
    dataFeatured?.nextToken ||
    dataPublic?.nextPaginationToken ||
    dataPrivate?.nextPaginationToken
  );

  // ----------------------------------

  useEffect(() => {
    if (dataFeatured?.nextToken) {
      setPaginationTokenFeatured(dataFeatured?.nextToken);
    }
  }, [dataFeatured?.nextToken]);

  useEffect(() => {
    if (dataPublic?.nextPaginationToken) {
      setPaginationTokenPublic(dataPublic?.nextPaginationToken);
    }
  }, [dataPublic?.nextPaginationToken]);

  useEffect(() => {
    if (dataPrivate?.nextPaginationToken) {
      setPaginationTokenPrivate(dataPrivate?.nextPaginationToken);
    }
  }, [dataPrivate?.nextPaginationToken]);

  useEffect(() => {
    setPaginationTokenFeatured(undefined);
    setPaginationTokenPublic(undefined);
    setPaginationTokenPrivate(undefined);
  }, [collectionId /*, sortBy, sortDirection*/]);

  const files: FileModelType[] = useMemo(
    () => mapModelsToFiles(models),
    [models]
  );

  const filteredFiles = useMemo(() => {
    return files.filter((file) => {
      const normalizedFileTags = file.meta.tags.map((item) =>
        tagIdToLabel(item).toLowerCase()
      );
      const searchTermLoweredAndTrimmed =
        searchTerm?.trim().toLowerCase() ?? "";

      const searchMatch =
        !searchTermLoweredAndTrimmed ||
        file.name?.toLowerCase().includes(searchTermLoweredAndTrimmed) ||
        normalizedFileTags.some((tag) =>
          tag.includes(searchTermLoweredAndTrimmed)
        );

      const statusMatch =
        !statusesFilter?.length || statusesFilter.includes(file.meta.status);

      const typeMatch = (() => {
        if (basesFilter) {
          return (
            !basesFilter?.length ||
            basesFilter?.some((type) => file.meta.type.startsWith(type))
          );
        } else if (typesFilter) {
          return (
            !typesFilter?.length ||
            typesFilter?.some((type) => file.meta.type.endsWith(type))
          );
        } else {
          return true;
        }
      })();
      const filterMatch = !customFilter || customFilter(file);

      const fileTags = file.meta.tags.map((tag) => tag.toLowerCase());
      const tagMatch =
        !tagsFilter?.length ||
        tagsFilter.some((tag) => fileTags.includes(tag.toLowerCase()));
      return searchMatch && statusMatch && filterMatch && typeMatch && tagMatch;
    });
  }, [
    files,
    customFilter,
    searchTerm,
    statusesFilter,
    basesFilter,
    typesFilter,
    tagsFilter,
  ]);

  return {
    files: filteredFiles,
    isLoading: isLoading || (hasMore && !filteredFiles.length),
    hasMore,
    loadMore: () => {},
    unfilteredFiles: files,
  };
}
