import { apiResponseMiddleware } from "infra/api/middlewares";
import { queryStatusToastMiddleware } from "infra/store/queryStatusToastMiddleware";

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { apiSlice } from "./apiSlice";
import { appReducer } from "./appReducer";

// config the store
const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiSlice.middleware,
      queryStatusToastMiddleware,
      apiResponseMiddleware
    ),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

export type Store = typeof store;
export type AppRootState = ReturnType<typeof appReducer>;

// export default the store
export default store;
