import useCountdown from "domains/teams/hooks/useCountdown";
import { formatCountdown } from "domains/teams/utils/formatCountdown";

import { Text, VStack } from "@chakra-ui/react";

const TYPES = [
  "generation",
  "upscale",
  "pixelate",
  "vectorize",
  "removeBackground",
  "magicPrompt",
  "detection",
  "download",
  "training",
] as const;
type Type = (typeof TYPES)[number];

export interface PlanCooldownProps {
  timeout?: number;
  type?: Type;
  isFreePlan?: boolean;
  isAdmin?: boolean;
}

interface LabelProps {
  isFreePlan: boolean;
  isAdmin: boolean;
}

const texts: {
  [key in Type]: {
    beforeCooldownString: ({ isFreePlan, isAdmin }: LabelProps) => string;
    afterCooldownString: ({ isFreePlan, isAdmin }: LabelProps) => string;
    cooldownOver: ({ isFreePlan, isAdmin }: LabelProps) => string;
    subText: ({ isFreePlan, isAdmin }: LabelProps) => string;
  };
} = {
  generation: {
    beforeCooldownString: ({ isFreePlan }) =>
      isFreePlan
        ? "Our free Starter plan has a wait time between generations. Kindly pause for"
        : "You have consumed all your creative units, you now have wait time between generations. Kindly pause for",
    afterCooldownString: (_) => "before your next generation.",
    cooldownOver: (_) =>
      "Your wait is over! You can close this window and continue generating. To avoid waiting in the future, consider upgrading your plan.",
    subText({ isFreePlan, isAdmin }) {
      return isFreePlan
        ? "Consider upgrading your plan for faster generations, more flexibility with canvas projects & collections, enhanced support, and collaborative workspaces."
        : `To avoid wait times you can${
            isAdmin ? "" : " ask an admin to"
          } upgrade your plan or buy creative units.`;
    },
  },
  training: {
    beforeCooldownString: ({ isFreePlan }) =>
      isFreePlan
        ? "Our free Starter plan has a wait time between trainings. Kindly pause for"
        : "You have consumed all your creative units, you now have wait time between trainings. Kindly pause for",
    afterCooldownString: (_) => "before your next training.",
    cooldownOver: (_) =>
      "Your wait is over! You can close this window and continue training. To avoid waiting in the future, consider upgrading your plan.",
    subText({ isFreePlan, isAdmin }) {
      return isFreePlan
        ? "Consider upgrading your plan for faster generations, more flexibility with canvas projects & collections, enhanced support, and collaborative workspaces."
        : `To avoid wait times you can${
            isAdmin ? "" : " ask an admin to"
          } upgrade your plan or buy creative units.`;
    },
  },
  upscale: {
    beforeCooldownString: ({ isFreePlan }) =>
      isFreePlan
        ? "Our free Starter plan has a wait time between enhancements. Kindly pause for"
        : "You have consumed all your creative units, you now have wait time between upscales. Kindly pause for",
    afterCooldownString: (_) => "before your next enhancement.",
    cooldownOver: (_) =>
      "Your wait is over! You can close this window and continue enhancing. To avoid waiting in the future, consider upgrading your plan.",
    subText({ isFreePlan, isAdmin }) {
      return isFreePlan
        ? "Consider upgrading your plan for faster upscales, more flexibility with canvas projects & collections, enhanced support, and collaborative workspaces."
        : `To avoid wait times you can${
            isAdmin ? "" : " ask an admin to"
          } upgrade your plan or buy creative units.`;
    },
  },
  pixelate: {
    beforeCooldownString: ({ isFreePlan }) =>
      isFreePlan
        ? "Our free Starter plan has a wait time between pixelations. Kindly pause for"
        : "You have consumed all your creative units, you now have wait time between pixelations. Kindly pause for",
    afterCooldownString: (_) => "before your next pixelation.",
    cooldownOver: (_) =>
      "Your wait is over! You can close this window and continue pixelating. To avoid waiting in the future, consider upgrading your plan.",
    subText({ isFreePlan, isAdmin }) {
      return isFreePlan
        ? "Consider upgrading your plan for faster pixelations, more flexibility with canvas projects & collections, enhanced support, and collaborative workspaces."
        : `To avoid wait times you can${
            isAdmin ? "" : " ask an admin to"
          } upgrade your plan or buy creative units.`;
    },
  },
  vectorize: {
    beforeCooldownString: ({ isFreePlan }) =>
      isFreePlan
        ? "Our free Starter plan has a wait time between vectorizations. Kindly pause for"
        : "You have consumed all your creative units, you now have wait time between vectorizations. Kindly pause for",
    afterCooldownString: (_) => "before your next vectorization.",
    cooldownOver: (_) =>
      "Your wait is over! You can close this window and continue vectorizing. To avoid waiting in the future, consider upgrading your plan.",
    subText({ isFreePlan, isAdmin }) {
      return isFreePlan
        ? "Consider upgrading your plan for faster vectorizations, more flexibility with canvas projects & collections, enhanced support, and collaborative workspaces."
        : `To avoid wait times you can${
            isAdmin ? "" : " ask an admin to"
          } upgrade your plan or buy creative units.`;
    },
  },
  removeBackground: {
    beforeCooldownString: ({ isFreePlan }) =>
      isFreePlan
        ? "Our free Starter plan has a wait time between background removals. Kindly pause for"
        : "You have consumed all your creative units, you now have wait time between background removals. Kindly pause for",
    afterCooldownString: (_) => "before your next background removal.",
    cooldownOver: (_) =>
      "Your wait is over! You can close this window and continue removing backgrounds. To avoid waiting in the future, consider upgrading your plan.",
    subText({ isFreePlan, isAdmin }) {
      return isFreePlan
        ? "Consider upgrading your plan for faster background removals, more flexibility with canvas projects & collections, enhanced support, and collaborative workspaces."
        : `To avoid wait times you can${
            isAdmin ? "" : " ask an admin to"
          } upgrade your plan or buy creative units.`;
    },
  },
  magicPrompt: {
    beforeCooldownString: (_) =>
      "You've reached the limit for using the prompt spark tool in your current plan. Please wait",
    afterCooldownString: (_) => "before using it again.",
    cooldownOver: (_) =>
      "Your wait is over! You can close this window and continue generating. To avoid waiting in the future, consider upgrading your plan.",
    subText: ({ isAdmin }) =>
      "To avoid wait times you can" +
      (isAdmin ? "" : " ask an admin to") +
      " upgrade your plan.",
  },
  detection: {
    beforeCooldownString: (_) =>
      "You have reached your plan's detection limit, you now have wait time between detections. Kindly pause for",
    afterCooldownString: (_) => "before your next detection.",
    cooldownOver: (_) =>
      "Your wait is over! You can close this window and continue detecting. To avoid waiting in the future, consider upgrading your plan.",
    subText: ({ isAdmin }) =>
      "To avoid wait times you can" +
      (isAdmin ? "" : " ask an admin to") +
      " upgrade your plan.",
  },
  download: {
    beforeCooldownString: (_) =>
      "You have reached your plan's download limit, you now have wait time between downloads. Kindly pause for",
    afterCooldownString: (_) => "before your next download.",
    cooldownOver: (_) =>
      "Your wait is over! You can close this window and continue downloading. To avoid waiting in the future, consider upgrading your plan.",
    subText: ({ isAdmin }) =>
      "To avoid wait times you can" +
      (isAdmin ? "" : " ask an admin to") +
      " upgrade your plan.",
  },
};

export default function PlanCooldown({
  timeout,
  type,
  isFreePlan,
  isAdmin,
}: PlanCooldownProps) {
  const countdown = useCountdown(timeout ?? 0);
  const str = formatCountdown(countdown);

  // should never happen, the props of PlanCooldown are only optional to please TS
  if (
    !type ||
    isFreePlan === undefined ||
    isAdmin === undefined ||
    timeout === undefined
  ) {
    return null;
  }

  // all those Texts are required for google translate to work
  return (
    <VStack align="center" pb={8} color="textSecondary" spacing={4}>
      <Text color="inherit">
        {countdown > 0 ? (
          <>
            <Text display="inline" color="inherit">
              {texts[type].beforeCooldownString({ isFreePlan, isAdmin })}{" "}
            </Text>
            <Text display="inline" fontWeight={"bold"}>
              {str}
            </Text>{" "}
            <Text display="inline" color="inherit">
              {texts[type].afterCooldownString({ isFreePlan, isAdmin })}
            </Text>
          </>
        ) : (
          texts[type].cooldownOver({ isFreePlan, isAdmin })
        )}
      </Text>
      <Text color="inherit">
        {texts[type].subText({ isFreePlan, isAdmin })}
      </Text>
    </VStack>
  );
}
