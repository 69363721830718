import type { ComponentStyleConfig } from "@chakra-ui/react";

export const Progress: ComponentStyleConfig = {
  variants: {
    primary: ({ colorScheme }) => {
      if (!colorScheme) {
        colorScheme = "primary";
      }

      return {
        filledTrack: {
          bg: `${colorScheme}.500`,
        },
        track: {
          bg: "whiteAlpha.100",
        },
      };
    },
  },
  defaultProps: {
    variant: "primary",
  },
};
