import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";

export const skyboxesEndpoints: Endpoints = {
  postSkyboxBase360Inferences: {
    invalidatesTags: [API_TAGS.job],
  },
  postSkyboxUpscale360Inferences: {
    invalidatesTags: [API_TAGS.job],
  },
};
