import { createContext, useContext, useMemo } from "react";

interface NavBarItemContextValue {
  isInMenu: boolean;
  onSubItemClick?: () => void;
}

export const NavBarItemContext = createContext<NavBarItemContextValue>({
  isInMenu: false,
  onSubItemClick: () => {},
});

export function useNavBarItemContext() {
  return useContext<NavBarItemContextValue>(NavBarItemContext);
}

// ------------------------------------

export function NavBarItemProvider({
  children = <></>,
  isInMenu = false,
  onSubItemClick,
}: {
  children?: React.ReactNode;
  isInMenu?: boolean;
  onSubItemClick?: () => void;
}) {
  const contextValue = useMemo(
    () => ({ isInMenu, onSubItemClick }),
    [isInMenu, onSubItemClick]
  );
  return (
    <NavBarItemContext.Provider value={contextValue}>
      {children}
    </NavBarItemContext.Provider>
  );
}
