import { Team } from "domains/teams/interfaces/Team";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";

import * as Sentry from "@sentry/react";

/**
 * Checks if the given asset belongs to the specified team.
 * @param asset - The asset to check ownership for.
 * @param team - The team to check ownership against.
 * @returns True if the asset belongs to the team, false otherwise.
 */
export function isOwner(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined,
  team: Team
) {
  if (!asset) return false;
  return asset.ownerId === team.id.replace(/%7C/g, "|");
}

export const getAssetIdFromSrc = (src: string) => {
  // https://github.com/scenario-labs/fawkes/issues/2804
  if (src === undefined) {
    Sentry.captureMessage("src is undefined in getAssetIdFromSrc");
    return "";
  }
  const splittedPathname =
    src.split("/assets/")[1] || src.split("/assets-transform/")[1];
  // https://github.com/scenario-labs/fawkes/issues/2804
  if (splittedPathname === undefined) {
    Sentry.captureMessage("splittedPathname is undefined in getAssetIdFromSrc");
    return "";
  }
  return splittedPathname.split("?")[0];
};

export function getImageFileName({
  model,
  inference,
  asset,
  options,
}: {
  model?: GetModelsByModelIdApiResponse["model"];
  inference?: GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"];
  asset: GetAssetsByAssetIdApiResponse["asset"];
  options?: { format?: "png" | "svg" | "jpeg" | "webp" };
}) {
  const prompt = asset.metadata.prompt ? `${asset.metadata.prompt}_` : "";
  let name = `${asset.id}_${prompt}${asset.metadata.type}`;
  if (asset.metadata.type.includes("inference") && inference) {
    const index = inference.images.findIndex((item) => item.id === asset.id);
    if (index && index !== -1) {
      name = `${name}_${index}`;
    }
    if (model) {
      name = `${
        model.status === "failed" ? "deleted-model" : model.name
      }_${name}`;
    }
  }

  name = `${name}_${Math.floor(new Date().getTime() / 1_000)}`;

  const extension =
    asset.mimeType === "image/svg+xml" ? "svg" : options?.format ?? "png";

  // Limit the filename to 255 characters and replace newlines with underscores
  return name.replace(/[\r\n]+/g, "_").slice(0, 251) + "." + extension;
}

export const reverseStrength = (value: number): number => {
  return parseFloat((1 - value + 0.01).toFixed(2));
};

export function getBase64Size(image: string) {
  try {
    const buffer = Buffer.from(image.substring(image.indexOf(",") + 1));
    return buffer.length;
  } catch (_) {
    throw new Error("Error getting base64 size");
  }
}

export function getBase64Mime(image: string) {
  const match = image.match(/^data:([^;]+);/);
  return match ? match[1] : undefined;
}
