import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import { mapAssetsToImagesFiles } from "domains/file-manager/interfaces";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetAssetsApiArg,
  GetAssetsByAssetIdApiResponse,
  useGetAssetsQuery,
} from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/dist/query";

export type UseAllAssetsArgs = {
  modelId?: string;
  collectionId?: string;
  types: GetAssetsByAssetIdApiResponse["asset"]["metadata"]["type"][];
  inferenceId?: string;
  parent?: GetAssetsByAssetIdApiResponse["asset"];
  sortBy?: GetAssetsApiArg["sortBy"];
  sortDirection?: GetAssetsApiArg["sortDirection"];
};

export default function useAllAssets(props: UseAllAssetsArgs | undefined) {
  const {
    modelId,
    collectionId,
    types,
    inferenceId,
    parent,
    sortBy,
    sortDirection,
  } = props ?? {};
  const canIncludeType = !modelId && !collectionId && !inferenceId && !parent;
  const { selectedTeam } = useTeamContext();
  const [paginationToken, setPaginationToken] = useState<string | undefined>();

  const debouncedRequestArgs = useDebounce<GetAssetsApiArg | typeof skipToken>(
    props
      ? {
          teamId: selectedTeam.id,
          paginationToken,
          pageSize: "100",
          modelId,
          collectionId,
          inferenceId,
          types: canIncludeType && types?.length ? types : undefined,
          parentAssetId: parent?.id,
          sortBy,
          sortDirection,
        }
      : skipToken,
    100
  );

  useEffect(() => {
    setPaginationToken(undefined);
  }, [
    collectionId,
    modelId,
    inferenceId,
    parent,
    types,
    sortBy,
    sortDirection,
  ]);

  const { data, isLoading, isFetching } =
    useGetAssetsQuery(debouncedRequestArgs);

  const loadMore = useCallback(() => {
    if (data?.nextPaginationToken) {
      setPaginationToken(data?.nextPaginationToken);
    }
  }, [data?.nextPaginationToken]);

  const files = useMemo(() => {
    if (isFetching && paginationToken === undefined) {
      return [];
    }
    let assets = data?.assets ?? [];
    if (!canIncludeType && types?.length) {
      assets = assets.filter((asset) => types.includes(asset.metadata.type));
    }

    return mapAssetsToImagesFiles(
      assets.filter((item) => item.metadata.type !== "canvas")
    );
  }, [data?.assets, types, canIncludeType, isFetching, paginationToken]);

  return {
    files,
    loadMore,
    hasMore: !!data?.nextPaginationToken,
    isLoading: isLoading || (isFetching && paginationToken === undefined),
  };
}
