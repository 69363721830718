import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { API_TAGS } from "infra/store/constants";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

export const updateAssetsQueryDataByUpdatingCollections = (
  teamId: string,
  type: "add" | "remove",
  assetIds: string[],
  collectionId: string,
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  const undoList: (() => void)[] = [];

  for (const assetId of assetIds) {
    for (const {
      endpointName,
      originalArgs,
    } of apiSlice.util.selectInvalidatedBy(getState(), [
      {
        type: API_TAGS.asset,
        id: `assetId:${assetId}`,
      },
    ]) as SelectedInvalidatedByTag[]) {
      if (endpointName === "getAssetsByAssetId") {
        const update = dispatch(
          apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
            draft.asset.collectionIds = getNewCollectionIds(
              draft.asset.collectionIds,
              type,
              collectionId
            );
          })
        );
        undoList.push(update.undo);
      }
    }
  }

  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.asset,
    API_TAGS.search,
  ]) as SelectedInvalidatedByTag[]) {
    if (endpointName === "getAssets") {
      if (originalArgs.collectionId === collectionId) {
        if (type === "remove") {
          const update = dispatch(
            apiSlice.util.updateQueryData(
              endpointName,
              originalArgs,
              (draft) => {
                draft.assets = draft.assets.filter(
                  (asset) => !assetIds.includes(asset.id)
                );
              }
            )
          );
          undoList.push(update.undo);
        }
      } else {
        const update = dispatch(
          apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
            draft.assets.forEach((asset) => {
              if (assetIds.includes(asset.id)) {
                asset.collectionIds = getNewCollectionIds(
                  asset.collectionIds,
                  type,
                  collectionId
                );
              }
            });
          })
        );
        undoList.push(update.undo);
      }
    }

    if (endpointName === "postSearch") {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          draft.results.forEach((item) => {
            if (item.type === "asset" && assetIds.includes(item.id)) {
              item.collectionIds = getNewCollectionIds(
                item.collectionIds ?? [],
                type,
                collectionId
              );
            }
          });
        })
      );
      undoList.push(update.undo);
    }

    if (endpointName === "getAssetsBulk") {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          draft.assets.forEach((asset) => {
            if (assetIds.includes(asset.id)) {
              asset.collectionIds = getNewCollectionIds(
                asset.collectionIds,
                type,
                collectionId
              );
            }
          });
        })
      );
      undoList.push(update.undo);
    }
  }

  return {
    undo: () => {
      undoList.forEach((undo) => undo());
    },
  };
};

export const updateModelsQueryDataByUpdatingCollections = (
  teamId: string,
  type: "add" | "remove",
  modelIds: string[],
  collectionId: string,
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  const undoList: (() => void)[] = [];

  for (const modelId of modelIds) {
    const update = dispatch(
      apiSlice.util.updateQueryData(
        "getModelsByModelId",
        {
          teamId,
          modelId,
        },
        (draft) => {
          draft.model.collectionIds = getNewCollectionIds(
            draft.model.collectionIds,
            type,
            collectionId
          );
        }
      )
    );
    undoList.push(update.undo);
  }

  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    API_TAGS.model,
    API_TAGS.search,
  ]) as SelectedInvalidatedByTag[]) {
    if (endpointName === "getModels") {
      if (originalArgs.collectionId === collectionId) {
        if (type === "remove") {
          const update = dispatch(
            apiSlice.util.updateQueryData(
              endpointName,
              originalArgs,
              (draft) => {
                draft.models = draft.models.filter(
                  (model) => !modelIds.includes(model.id)
                );
              }
            )
          );
          undoList.push(update.undo);
        }
      } else {
        const update = dispatch(
          apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
            draft.models.forEach((model) => {
              if (modelIds.includes(model.id)) {
                model.collectionIds = getNewCollectionIds(
                  model.collectionIds,
                  type,
                  collectionId
                );
              }
            });
          })
        );
        undoList.push(update.undo);
      }
    }

    if (endpointName === "postSearch") {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          draft.results.forEach((item) => {
            if (item.type === "model" && modelIds.includes(item.id)) {
              item.collectionIds = getNewCollectionIds(
                item.collectionIds ?? [],
                type,
                collectionId
              );
            }
          });
        })
      );
      undoList.push(update.undo);
    }
  }

  return {
    undo: () => {
      undoList.forEach((undo) => undo());
    },
  };
};

const getNewCollectionIds = (
  currentCollectionIds: string[],
  type: "add" | "remove",
  newCollectionId: string
): string[] => {
  if (type === "add") {
    return [...currentCollectionIds, newCollectionId];
  } else if (type === "remove") {
    return currentCollectionIds.filter((item) => item !== newCollectionId);
  }
  return currentCollectionIds;
};
