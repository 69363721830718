import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  bg: "backgroundQuaternary.500",
  color: "textSecondary",
  borderRadius: "lg",
  borderWidth: 1,
  "--popper-arrow-bg": "var(--chakra-colors-backgroundQuaternary-500)",
  px: 3,
  py: 2,
});

export const Tooltip = defineStyleConfig({
  baseStyle,
  variants: {
    danger: {
      color: "danger.500",
      bg: "background.500",
      "--popper-arrow-bg": "var(--chakra-colors-background-500)",
      borderColor: "danger.800",
    },
  },
});
