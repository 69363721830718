import { useCallback, useMemo, useState } from "react";
import SupportFormModal from "domains/navigation/components/TopBar/SupportFormModal";
import { useChatContext } from "domains/navigation/contexts/ChatProvider";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import { Menu, MenuButton, MenuList } from "@chakra-ui/react";

type ActionListItem = {
  id: string;
  label: string;
  externalLink?: ButtonProps["externalLink"];
  onClick?: "toggleChat" | "openSupportFormModal" | (() => void);
};

const ACTION_LIST: ActionListItem[] = [
  {
    id: "support",
    label: "Contact Us",
    onClick: "openSupportFormModal",
  },
  {
    id: "knowledgeCenter",
    label: "Knowledge Center",
    externalLink: "https://help.scenario.com",
    onClick: () => {
      Track(AnalyticsEvents.Navigation.ClickedHelpCenter);
    },
  },
  {
    id: "chat",
    label: "Chat Support",
    onClick: "toggleChat",
  },
];

export default function HelpButtonDropdown() {
  const { selectedTeam } = useTeamContext();
  const { isChatVisible, setIsChatVisible } = useChatContext();
  const [isSupportFormModalOpen, setIsSupportFormModalOpen] =
    useState<boolean>(false);

  const actionList = useMemo(
    () =>
      ACTION_LIST.filter((item) => {
        return !(item.id === "support" && selectedTeam.isFreePlan);
      }),
    [selectedTeam.isFreePlan]
  );

  const handleSupportFormModalOpen = useCallback(() => {
    setIsSupportFormModalOpen(true);
  }, [setIsSupportFormModalOpen]);

  const handleSupportFormModalClose = useCallback(() => {
    setIsSupportFormModalOpen(false);
  }, [setIsSupportFormModalOpen]);

  return (
    <>
      <SupportFormModal
        isOpen={isSupportFormModalOpen}
        onClose={handleSupportFormModalClose}
      />

      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          pos="relative"
          w="36px"
          h="36px"
          p={0}
          borderRadius="full"
          _hover={{
            bgColor: "backgroundTertiary.500",
          }}
          variant="unstyled"
        >
          <Icon id="Layout/Help" h="20px" color="textSecondary" />
        </MenuButton>

        <MenuList overflowY="auto" w="220px" maxH="80vh" px={2}>
          {actionList.map((item) => {
            const onClick = (() => {
              if (item.onClick === "toggleChat") {
                return () => {
                  if (isChatVisible) {
                    setIsChatVisible(false);
                    return;
                  }
                  setIsChatVisible(true);
                  Track(AnalyticsEvents.Navigation.ClickedChat);
                };
              } else if (item.onClick === "openSupportFormModal") {
                return () => {
                  handleSupportFormModalOpen();
                  Track(AnalyticsEvents.Navigation.ClickedSupport);
                };
              } else if (typeof item.onClick === "function") {
                return item.onClick;
              }
            })();

            return (
              <MenuItem
                key={item.id}
                externalLink={item.externalLink}
                onClick={onClick}
                text={item.label}
              />
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
}
