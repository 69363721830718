import { useCallback } from "react";
import { LinkProps } from "next/link";
import { useNavBarItemContext } from "domains/navigation/contexts/NavBarItemProvider";
import { extraTheme } from "domains/theme";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import { Box, Center, HStack, Text } from "@chakra-ui/react";

interface NavBarSubItemProps {
  text: string;
  icon?: React.ReactNode;
  isActive?: boolean;
  link?: LinkProps["href"];
  onClick?: () => void;
  dataTestId?: string;
  isDisabled?: boolean;
}

export default function NavBarSubItem({
  text,
  icon,
  isActive,
  link,
  onClick,
  dataTestId,
  isDisabled,
}: NavBarSubItemProps) {
  const { isInMenu, onSubItemClick } = useNavBarItemContext();

  const handleClick = useCallback(() => {
    if (onSubItemClick) onSubItemClick();
    if (onClick) onClick();
  }, [onSubItemClick, onClick]);

  return (
    <HStack
      as={Button}
      w="100%"
      p={2}
      _hover={{
        bg: "whiteAlpha.200",
      }}
      colorScheme="white"
      data-group
      data-testid={dataTestId}
      internalLink={isDisabled ? undefined : link}
      isDisabled={isDisabled}
      onClick={handleClick}
      spacing={3}
      variant="ghost"
    >
      {!isInMenu && (
        <Box pos="relative" top="-10px" pl="8px">
          <Box pos="relative" mr={-1}>
            <Icon id="Ui/TreeBranch" color="border.500" h="20px" />
            <Icon
              id="Ui/TreeLine"
              color="border.500"
              h="34px"
              pos="absolute"
              left="0px"
              top="-30px"
            />
          </Box>
        </Box>
      )}

      {!!icon && (
        <Center
          w="14px"
          color={isActive ? "textPrimary" : "textSecondary"}
          _groupHover={{ color: "textPrimary" }}
        >
          {icon}
        </Center>
      )}

      <Text
        align="left"
        flex={1}
        color={isActive ? "textPrimary" : "textSecondary"}
        fontSize="sm"
        _groupHover={{ color: "textPrimary" }}
        transition={extraTheme.transitions.fast}
        isTruncated
      >
        {text}
      </Text>
    </HStack>
  );
}
