"use client";
import React, { createContext, useContext, useState } from "react";
import dynamic from "next/dynamic";

import { InkeepChatButtonProps, InkeepCustomTriggerProps } from "@inkeep/uikit";
import {
  InkeepBaseSettings,
  InkeepModalSettings,
} from "@inkeep/uikit/dist/components/InkeepWidgetProps";

const baseSettings: InkeepBaseSettings = {
  apiKey: process.env.NEXT_PUBLIC_INKEEP_API_KEY!,
  integrationId: process.env.NEXT_PUBLIC_INKEEP_INTEGRATION_ID!,
  organizationId: process.env.NEXT_PUBLIC_INKEEP_ORGANIZATION_ID!,
  primaryBrandColor: "#0088CC",
  organizationDisplayName: "Scenario",
  env: process.env.NODE_ENV !== "production" ? "DEVELOPMENT" : "PRODUCTION",
  colorMode: {
    forcedColorMode: "dark", // options: 'light' or dark'
  },
};

const aiChatSettings = {
  botAvatarSrcUrl: "/icon.png",
  quickQuestions: [
    "How can I train a character model?",
    "Where to get training data?",
    "How to enhance an image via API?",
    "What is Flux?",
    "What are the recommended image dimensions for training?",
  ],
};

const modalSettings: InkeepModalSettings = {
  defaultView: "AI_CHAT",
};

const InkeepCustomTrigger = dynamic(
  () => import("@inkeep/uikit").then((mod) => mod.InkeepCustomTrigger),
  {
    ssr: false,
  }
);
const InkeepChatButton = dynamic(
  () => import("@inkeep/uikit").then((mod) => mod.InkeepChatButton),
  {
    ssr: false,
  }
);

interface ChatProviderContext {
  setIsChatVisible: (isChatVisible: boolean) => void;
  isChatVisible: boolean;
}

export const ChatContext = createContext<ChatProviderContext>({
  setIsChatVisible: () => {},
  isChatVisible: false,
});

export function ChatProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const [isChatVisible, setIsChatVisible] = useState<boolean>(false);

  const inkeepCustomTriggerProps: InkeepCustomTriggerProps = {
    isOpen: isChatVisible,
    onClose: () => setIsChatVisible(false),
    baseSettings,
    aiChatSettings,
    modalSettings,
  };

  const inkeepChatButtonProps: InkeepChatButtonProps = {
    chatButtonType: "PILL",
    baseSettings,
    aiChatSettings,
    modalSettings,
  };

  return (
    <ChatContext.Provider
      value={{
        setIsChatVisible,
        isChatVisible,
      }}
    >
      {children}
      <InkeepCustomTrigger {...inkeepCustomTriggerProps} />
      <InkeepChatButton {...inkeepChatButtonProps} />
    </ChatContext.Provider>
  );
}

export function useChatContext() {
  return useContext<ChatProviderContext>(ChatContext);
}
