import useAllAssets from "domains/assets/hooks/useAllAssets";
import { FILTER_ASSET_TYPES } from "domains/file-manager/constants/AssetFilter";
import useAllModels from "domains/models/hooks/useAllModels";

export default function useTeamHasData() {
  const { files: assets, isLoading: isAssetsLoading } = useAllAssets({
    types: Object.values(FILTER_ASSET_TYPES)
      .map((type) => type.value)
      .flat(),
  });

  const { files: models, isLoading: isModelsLoading } = useAllModels({
    privacyFilter: "private",
  });

  return {
    hasData: assets.length > 0 || models.length > 0,
    isLoading: isAssetsLoading || isModelsLoading,
  };
}
