import { extraTheme } from "domains/theme";

import { sliderAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle((props) => {
  const { colorScheme } = props;

  return {
    mark: {
      _hover: {
        transition: "none",
      },
    },
    thumb: {
      bg: `secondary.900`,
      borderColor: `${colorScheme}.500`,
      h: "12px",
      w: "12px",
      boxShadow: "0px 0px 0px 2px var(--chakra-colors-secondary-900)",
      borderWidth: "2px",
      transition: extraTheme.transitions.fast,
      _hover: {
        transition: "none",
        borderColor: `${colorScheme}.900`,
      },
      _groupActive: {
        transition: "none",
        borderColor: `${colorScheme}.900`,
      },
      _groupHover: {
        transition: "none",
        borderColor: `${colorScheme}.900`,
      },
      _groupFocus: {
        borderColor: `${colorScheme}.900`,
      },
      _groupDisabled: {
        borderColor: "secondary.700",
        bg: "secondary.800",
      },
    },
    track: {
      h: "2px",
      overflow: "visible",
      borderRadius: 0,

      "&::before, &::after": {
        content: '""',
        color: "transparent",
        pos: "absolute",
        top: 0,
        w: "6px",
        h: "2px",
        bgColor: "var(--slider-bg)",
      },
      _before: {
        left: "-6px",
        borderLeftRadius: "full",
      },
      _after: {
        right: "-6px",
        borderRightRadius: "full",
      },

      _hover: {
        transition: "none",
      },
    },

    filledTrack: {
      bg: `${colorScheme}.500`,
      transition: extraTheme.transitions.fast,
      mx: "-6px",
      borderRadius: "full",

      _hover: {
        transition: "none",
      },

      _groupHover: {
        transition: "none",
      },
      _groupActive: {
        transition: "none",
      },
    },
  };
});

const variants = {
  unstyled: definePartsStyle(() => ({
    track: {
      borderRadius: "full",

      "&::before, &::after": {
        display: "none",
      },
    },

    filledTrack: {
      mx: 0,
    },
  })),
};

export const Slider = defineMultiStyleConfig({ baseStyle, variants });
